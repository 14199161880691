// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs {
    display: flex;
    justify-content: center; /* Center the buttons */
    margin-bottom: 10px;
}

.tabs-container {
    width: 50vw; /* Set width to half the viewport width */
    max-width: 800px; /* Optional: Limit the maximum width */
    display: flex;
    justify-content: space-around; /* Spread the buttons evenly */
    border-bottom: 1px solid #ddd; /* Optional: Bottom border */
}

.tabs button {
    padding: 10px 20px;
    cursor: pointer;
    background: none;
    border: none;
    border-bottom: 3px solid transparent;
    margin-right: 10px;
    flex: 1 1; /* Allow the buttons to stretch evenly */
    text-align: center;
}

.tabs button.active {
    border-bottom: 3px solid #007bff;
    color: #007bff;
}

.tab-content {
    padding: 20px;
    background-color: #f9f9f9;
}
`, "",{"version":3,"sources":["webpack://./src/components/TabLayout.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB,EAAE,uBAAuB;IAChD,mBAAmB;AACvB;;AAEA;IACI,WAAW,EAAE,yCAAyC;IACtD,gBAAgB,EAAE,sCAAsC;IACxD,aAAa;IACb,6BAA6B,EAAE,8BAA8B;IAC7D,6BAA6B,EAAE,4BAA4B;AAC/D;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,oCAAoC;IACpC,kBAAkB;IAClB,SAAO,EAAE,wCAAwC;IACjD,kBAAkB;AACtB;;AAEA;IACI,gCAAgC;IAChC,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B","sourcesContent":[".tabs {\n    display: flex;\n    justify-content: center; /* Center the buttons */\n    margin-bottom: 10px;\n}\n\n.tabs-container {\n    width: 50vw; /* Set width to half the viewport width */\n    max-width: 800px; /* Optional: Limit the maximum width */\n    display: flex;\n    justify-content: space-around; /* Spread the buttons evenly */\n    border-bottom: 1px solid #ddd; /* Optional: Bottom border */\n}\n\n.tabs button {\n    padding: 10px 20px;\n    cursor: pointer;\n    background: none;\n    border: none;\n    border-bottom: 3px solid transparent;\n    margin-right: 10px;\n    flex: 1; /* Allow the buttons to stretch evenly */\n    text-align: center;\n}\n\n.tabs button.active {\n    border-bottom: 3px solid #007bff;\n    color: #007bff;\n}\n\n.tab-content {\n    padding: 20px;\n    background-color: #f9f9f9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
