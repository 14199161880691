import React, { useState, useEffect } from 'react';
import BatteryTypesTable from './BatteryTypesTable';
import AddBatteryTypeForm from './AddBatteryTypeForm';
import UpdateBatteryTypeForm from './UpdateBatteryTypeForm'; // Import the new form

function BatteryTypesSection({ activeTab, batteryTypes, onBatteryTypeAdded }) {
    const [refreshTrigger, setRefreshTrigger] = useState(false); // Local refresh trigger state

    const handleBatteryTypeAdded = () => {
        if (onBatteryTypeAdded && typeof onBatteryTypeAdded === 'function') {
            onBatteryTypeAdded();
        }
        setRefreshTrigger(prev => !prev);
    };

    const handleBatteryTypeUpdated = () => {
        setRefreshTrigger(prev => !prev); // Trigger refresh after an update
    };

    useEffect(() => {
        console.log('BatteryTypesSection Props:', { activeTab, batteryTypes, refreshTrigger });
    }, [batteryTypes, refreshTrigger]);

    return (
        <div>
            <h1>Battery Types</h1>
            <AddBatteryTypeForm onBatteryTypeAdded={handleBatteryTypeAdded} />
            {/* New form for updating battery type */}
            <UpdateBatteryTypeForm onBatteryTypeUpdated={handleBatteryTypeUpdated} />
            <BatteryTypesTable batteryTypes={batteryTypes} refreshTrigger={refreshTrigger} />
        </div>
    );
}

export default BatteryTypesSection;
