import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import FlashlightsPage from './pages/FlashlightsPage';
import BatteriesPage from './pages/BatteriesPage';
import BatteryAssignmentPage from './pages/BatteryAssignmentPage';
import EmitterTestingPage from './pages/EmitterTestingPage';
import FlashlightEmitterTestsPage from './pages/FlashlightEmitterTestsPage';
import EmittersPage from './pages/EmittersPage'; // Import your new EmittersPage component
import PasswordProtect from './components/PasswordProtect'; // Import PasswordProtect component
import './styles/tables.css';
import './styles/dark-theme.css';

function App() {
    const [hasAccess, setHasAccess] = useState(false);

    useEffect(() => {
        // Check if the token is present in localStorage to grant access
        const token = localStorage.getItem('authToken');
        if (token) {
            setHasAccess(true);
        }
    }, []);

    if (!hasAccess) {
        // Show the password protection page if access is not granted
        return <PasswordProtect onAccessGranted={() => setHasAccess(true)} />;
    }

    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/flashlights" element={<FlashlightsPage />} />
                <Route path="/batteries" element={<BatteriesPage />} />
                <Route path="/assignments" element={<BatteryAssignmentPage />} />
                <Route path="/emitters" element={<EmittersPage />} /> {/* New Emitters page */}
                <Route path="/emitterTests" element={<EmitterTestingPage />} /> {/* Old Emitter Testing page */}
                <Route path="/flashlights/:flashlightId/emitters" element={<FlashlightEmitterTestsPage />} />
                <Route path="/" element={<FlashlightsPage />} /> {/* Default route */}
            </Routes>
        </Router>
    );
}

export default App;
