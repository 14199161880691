import React, { useState, useEffect } from 'react';
import EmittersTable from '../components/EmittersTable';
import AddEmitterForm from '../components/AddEmitterForm';
import { fetchEmitters, createEmitter, updateEmitter, deleteEmitter } from '../services/emitterService';
import { fetchFlashlights } from '../services/flashlightService'; // Make sure this is imported

function EmittersPage() {
    const [emitters, setEmitters] = useState([]);
    const [flashlights, setFlashlights] = useState([]); // Track flashlights in state
    const [editingEmitter, setEditingEmitter] = useState(null);

    useEffect(() => {
        loadEmitters();
        loadFlashlights(); // Load flashlights when the component mounts
    }, []);

    const loadEmitters = async () => {
        const emittersData = await fetchEmitters();
        setEmitters(emittersData);
    };

    const loadFlashlights = async () => {
        const flashlightsData = await fetchFlashlights();
        setFlashlights(flashlightsData); // Populate flashlights state
    };

    const handleSaveEmitter = async (emitterData) => {
        if (editingEmitter) {
            await updateEmitter(editingEmitter.id, emitterData);
            setEditingEmitter(null);
        } else {
            await createEmitter(emitterData);
        }
        loadEmitters();
    };

    const handleEditEmitter = (emitter) => {
        setEditingEmitter(emitter);
    };

    const handleDeleteEmitter = async (emitterId) => {
        await deleteEmitter(emitterId);
        loadEmitters();
    };

    return (
        <div>
            <h1>Manage Emitters</h1>
            <AddEmitterForm
                onSaveEmitter={handleSaveEmitter}
                flashlights={flashlights} // Pass flashlights to the form
                editingEmitter={editingEmitter}
                onCancelEdit={() => setEditingEmitter(null)}
            />
            <EmittersTable
                emitters={emitters}
                onEditEmitter={handleEditEmitter}
                onDeleteEmitter={handleDeleteEmitter}
            />
        </div>
    );
}

export default EmittersPage;
