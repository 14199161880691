import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { fetchFlashlights, deleteFlashlight } from '../services/flashlightService'; // If you have these services, keep them

const AUTH_TOKEN = 'Your-Secret-Token';

function FlashlightTable({ onFlashlightRemoved, onFlashlightUpdated }) {
    const [flashlights, setFlashlights] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'make', direction: 'ascending' });
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const [editableRow, setEditableRow] = useState(null); // Track the row being edited
    const [editData, setEditData] = useState({}); // Track changes to the editable row
    const [loading, setLoading] = useState(true); // Track loading state
    const [error, setError] = useState(''); // Track error state

    // Define headers with the authorization token
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${AUTH_TOKEN}`
    };

    // Fetch all flashlights when the component mounts using axios
    const fetchAllFlashlights = () => {
        setLoading(true);
        axios.get('https://blackumbra.com/api/flashlights', { headers })
            .then(response => {
                setFlashlights(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching flashlights:', error);
                setError('Failed to fetch flashlights');
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchAllFlashlights(); // Call the function to fetch flashlights data
    }, []);

    const formatDateForDisplay = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        return `${month}-${day}-${year}`;
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getSortIndicator = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '▲' : '▼';
        }
        return '';
    };

    const handleViewEmitterResults = (flashlightId) => {
        navigate(`/flashlights/${flashlightId}/emitters`);
    };

    // Handle delete flashlight using axios
    const handleDeleteFlashlight = (flashlightId) => {
        if (window.confirm('Are you sure you want to delete this flashlight?')) {
            axios.delete(`https://blackumbra.com/api/flashlights/${flashlightId}`, { headers })
                .then(() => {
                    setFlashlights(prevFlashlights => prevFlashlights.filter(f => f.id !== flashlightId));
                    alert('Flashlight deleted successfully');
                })
                .catch(error => {
                    console.error('Error deleting flashlight:', error);
                    alert(`Failed to delete flashlight: ${error.message}`);
                });
        }
    };

    const handleEditClick = (flashlight) => {
        const formatDateForInput = (dateString) => {
            if (!dateString) return '';
            const date = new Date(dateString);
            return date.toISOString().split('T')[0];
        };

        setEditableRow(flashlight.id);
        setEditData({
            ...flashlight,
            date_acquired: formatDateForInput(flashlight.date_acquired),
        });
    };

    // Save changes to the server using axios
    const handleSaveClick = () => {
        const formatDateForDB = (dateString) => {
            if (!dateString) return null;
            const date = new Date(dateString);
            return date.toISOString().split('T')[0];
        };

        const formattedData = {
            ...editData,
            date_acquired: formatDateForDB(editData.date_acquired)
        };

        axios.put(`https://blackumbra.com/api/flashlights/${editableRow}`, formattedData, { headers })
            .then(response => {
                alert('Flashlight updated successfully!');
                onFlashlightUpdated(); // Refresh list in parent component
                setEditableRow(null);
            })
            .catch(error => {
                console.error('Error updating flashlight:', error);
                alert(`Error updating flashlight: ${error.response?.data?.message || error.message}`);
            });
    };

    const handleCancelClick = () => {
        setEditableRow(null);
        setEditData({});
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditData({
            ...editData,
            [name]: value
        });
    };

    const filteredFlashlights = flashlights.filter((flashlight) => {
        const searchLower = searchQuery.toLowerCase();
        const includesSearchQuery = (value) => {
            return value && value.toString().toLowerCase().includes(searchLower);
        };

        return (
            includesSearchQuery(flashlight.make) ||
            includesSearchQuery(flashlight.model) ||
            includesSearchQuery(flashlight.battery_size) ||
            includesSearchQuery(flashlight.battery_chemistry) ||
            includesSearchQuery(flashlight.ip_rating) ||
            includesSearchQuery(flashlight.date_acquired) ||
            includesSearchQuery(flashlight.nickname) ||
            includesSearchQuery(flashlight.headstamp) || 
            includesSearchQuery(flashlight.max_slots) ||
            includesSearchQuery(flashlight.emitter_types) ||
            includesSearchQuery(flashlight.notes)
        );
    });

    const sortedFlashlights = [...filteredFlashlights].sort((a, b) => {
        if (a[sortConfig.key] === null) return 1;
        if (b[sortConfig.key] === null) return -1;
        if (a[sortConfig.key] === b[sortConfig.key]) return 0;
        return (a[sortConfig.key] > b[sortConfig.key] ? 1 : -1) * (sortConfig.direction === 'ascending' ? 1 : -1);
    });

    const formatEmitters = (emitters) => {
        if (!emitters) return 'N/A'; 
        if (Array.isArray(emitters)) {
            const uniqueEmitterTypes = new Set();
            emitters.forEach(emitter => {
                uniqueEmitterTypes.add(emitter.type); 
            });
            const uniqueEmittersList = Array.from(uniqueEmitterTypes);
            return uniqueEmittersList.length > 0 ? uniqueEmittersList.join(', ') : 'N/A';
        }
        return emitters.toString(); 
    };

    return (
        <div>
            <h2>Flashlight List</h2>
            {/* Display count of filtered results */}
            <p>Showing {filteredFlashlights.length} of {flashlights.length} results</p>
            <input
                type="text"
                placeholder="Search by make, model, battery size, etc."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
            />
            <table style={{ width: '100%', border: '1px solid black' }}>
                <thead>
                    <tr>
                        <th onClick={() => handleSort('make')}>Make {getSortIndicator('make')}</th>
                        <th onClick={() => handleSort('model')}>Model {getSortIndicator('model')}</th>
                        <th onClick={() => handleSort('battery_size')}>Battery Size {getSortIndicator('battery_size')}</th>
                        <th onClick={() => handleSort('battery_chemistry')}>Battery Chemistry {getSortIndicator('battery_chemistry')}</th>
                        <th onClick={() => handleSort('ip_rating')}>IP Rating {getSortIndicator('ip_rating')}</th>
                        <th onClick={() => handleSort('date_acquired')}>Date Acquired {getSortIndicator('date_acquired')}</th>
                        <th onClick={() => handleSort('nickname')}>Nickname {getSortIndicator('nickname')}</th>
                        <th onClick={() => handleSort('headstamp')}>Headstamp {getSortIndicator('headstamp')}</th>
                        <th onClick={() => handleSort('max_slots')}>Max Slots {getSortIndicator('max_slots')}</th>
                        <th onClick={() => handleSort('emitter_types')}>Emitters {getSortIndicator('emitter_types')}</th>
                        <th onClick={() => handleSort('notes')}>Notes {getSortIndicator('notes')}</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedFlashlights.length > 0 ? (
                        sortedFlashlights.map((flashlight) => (
                            <tr key={flashlight.id}>
                                {editableRow === flashlight.id ? (
                                    <>
                                        <td><input name="make" value={editData.make} onChange={handleInputChange} /></td>
                                        <td><input name="model" value={editData.model} onChange={handleInputChange} /></td>
                                        <td><input name="battery_size" value={editData.battery_size} onChange={handleInputChange} /></td>
                                        <td><input name="battery_chemistry" value={editData.battery_chemistry} onChange={handleInputChange} /></td>
                                        <td><input name="ip_rating" value={editData.ip_rating} onChange={handleInputChange} /></td>
                                        <td><input name="date_acquired" type="date" value={editData.date_acquired} onChange={handleInputChange} /></td>
                                        <td><input name="nickname" value={editData.nickname} onChange={handleInputChange} /></td>
                                        <td><input name="headstamp" value={editData.headstamp} onChange={handleInputChange} /></td>
                                        <td><input name="max_slots" type="number" min="1" value={editData.max_slots} onChange={handleInputChange} /></td>
                                        <td>{formatEmitters(flashlight.emitter_types) || 'N/A'}</td>
                                        <td><input name="notes" value={editData.notes} onChange={handleInputChange} /></td>
                                        <td>
                                            <button onClick={handleSaveClick} style={{ color: 'green' }}>Save</button>
                                            <button onClick={handleCancelClick} style={{ marginLeft: '10px', color: 'red' }}>Cancel</button>
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td>{flashlight.make || 'N/A'}</td>
                                        <td>{flashlight.model || 'N/A'}</td>
                                        <td>{flashlight.battery_size || 'N/A'}</td>
                                        <td>{flashlight.battery_chemistry || 'N/A'}</td>
                                        <td>{flashlight.ip_rating || 'N/A'}</td>
                                        <td>{flashlight.date_acquired ? formatDateForDisplay(flashlight.date_acquired) : 'N/A'}</td>
                                        <td>{flashlight.nickname || 'N/A'}</td>
                                        <td>{flashlight.headstamp || 'N/A'}</td>
                                        <td>{flashlight.max_slots || 'N/A'}</td>
                                        <td>{formatEmitters(flashlight.emitter_types)}</td>
                                        <td>{flashlight.notes || 'N/A'}</td>
                                        <td>
                                            <button onClick={() => handleViewEmitterResults(flashlight.id)}>View Emitter Results</button>
                                            <button onClick={() => handleEditClick(flashlight)} style={{ marginLeft: '10px' }}>Edit</button>
                                            <button onClick={() => handleDeleteFlashlight(flashlight.id)} style={{ marginLeft: '10px', color: 'red' }}>Delete</button>
                                        </td>
                                    </>
                                )}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="12">No flashlights available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default FlashlightTable;
