import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchEmitterTestsByFlashlightId, updateEmitterTestResult, deleteEmitterTestResult } from '../services/emitterTestService';
import { fetchFlashlightById } from '../services/flashlightService'; // Import function to fetch flashlight details
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function FlashlightEmitterTestsPage() {
    const { flashlightId } = useParams();
    const navigate = useNavigate();
    const [flashlight, setFlashlight] = useState(null); // State to store flashlight details
    const [emitterTests, setEmitterTests] = useState([]);
    const [error, setError] = useState('');
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [editableRow, setEditableRow] = useState(null);
    const [editData, setEditData] = useState({});

    useEffect(() => {
        if (flashlightId) {
            // Fetch flashlight details
            fetchFlashlightById(flashlightId)
                .then(data => {
                    setFlashlight(data);
                })
                .catch(err => setError('Failed to load flashlight details.'));

            // Fetch emitter tests
            fetchEmitterTestsByFlashlightId(flashlightId)
                .then(data => {
                    console.log('Fetched emitter test results:', data);
                    const formattedData = data.map(item => ({
                        ...item,
                        test_date: item.test_date ? new Date(item.test_date) : null,
                    }));
                    setEmitterTests(formattedData);
                })
                .catch(err => setError('Failed to load emitter tests.'));
        }
    }, [flashlightId, refreshTrigger]); // Ensure `refreshTrigger` triggers a re-fetch

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditData(prevState => ({ ...prevState, [name]: value })); // Correctly update state
    };

    const handleDateChange = (date) => {
        setEditData(prevState => ({ ...prevState, test_date: date })); // Correctly update state
    };

    const handleEditClick = (test) => {
        console.log('Edit clicked for test ID:', test.test_id);
        setEditableRow(test.test_id);
        setEditData({
            ...test,
            notes: test.test_notes || '' // Ensure notes are initialized correctly in edit state
        });
    };

    const handleSaveClick = () => {
        console.log('Data being sent to API:', editData);

        updateEmitterTestResult(editableRow, editData)
            .then(() => {
                alert('Emitter test updated successfully!');
                setEditableRow(null);
                setEditData({});
                setRefreshTrigger(prev => prev + 1); // Refresh the data to reflect changes
            })
            .catch(error => {
                console.error('Error updating emitter test:', error);
                alert(`Error updating emitter test: ${error.message}`);
            });
    };

    const handleCancelClick = () => {
        setEditableRow(null);
        setEditData({});
    };

    const handleDeleteClick = (testId) => {
        if (window.confirm('Are you sure you want to delete this emitter test?')) {
            deleteEmitterTestResult(testId)
                .then(() => {
                    alert('Emitter test deleted successfully!');
                    setRefreshTrigger(prev => prev + 1);
                })
                .catch(error => alert(`Error deleting emitter test: ${error.message}`));
        }
    };

    const handleBackClick = () => {
        navigate('/flashlights');
    };

    // Helper function to format numbers with error handling
    const formatNumber = (value, decimals) => {
        if (value !== undefined && value !== null && !isNaN(value)) {
            return Number(value).toFixed(decimals);
        }
        return 'N/A';
    };

    const getFlashlightName = () => {
        if (!flashlight) return '';
        const { make, model, nickname, headstamp } = flashlight;
        const nicknamePart = nickname ? ` "${nickname}"` : '';
        const headstampPart = headstamp ? ` (${headstamp})` : '';
        return `${make} ${model}${nicknamePart}${headstampPart}`;
    };

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    if (!emitterTests.length) {
        return (
            <div>
                <button onClick={handleBackClick} style={{ marginBottom: '20px', padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}>
                    Back to Flashlights
                </button>
                <p>No emitter test results available for this flashlight.</p>
            </div>
        );
    }

    return (
        <div>
            <button onClick={handleBackClick} style={{ marginBottom: '20px', padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}>
                Back to Flashlights
            </button>

            <h2>
                Emitter Test Results for {getFlashlightName()} (ID: {flashlightId})
            </h2>
            <table style={{ width: '100%', border: '1px solid black' }}>
                <thead>
                    <tr>
                        <th>Emitter ID</th>
                        <th>Type (Read-Only)</th>
                        <th>Driver Type (Read-Only)</th>
                        <th>Measured CCT</th>
                        <th>Measured Ra</th>
                        <th>Measured R9</th>
                        <th>Measured DUV</th>
                        <th>Measured Lux</th>
                        <th>Distance Tested</th>
                        <th>Test Date</th>
                        <th>Notes</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {emitterTests.map((testResult) => (
                        <tr key={testResult.test_id}>
                            {editableRow === testResult.test_id ? (
                                <>
                                    <td>{testResult.emitter_id}</td>
                                    <td>{testResult.type}</td>
                                    <td>{testResult.driver_type}</td>
                                    <td><input name="measured_cct" value={editData.measured_cct || ''} onChange={handleInputChange} /></td>
                                    <td><input name="measured_ra" value={editData.measured_ra || ''} onChange={handleInputChange} /></td>
                                    <td><input name="measured_r9" value={editData.measured_r9 || ''} onChange={handleInputChange} /></td>
                                    <td><input name="measured_duv" value={editData.measured_duv || ''} onChange={handleInputChange} /></td>
                                    <td><input name="measured_lux" value={editData.measured_lux || ''} onChange={handleInputChange} /></td>
                                    <td><input name="distance_tested" value={editData.distance_tested || ''} onChange={handleInputChange} /></td>
                                    <td>
                                        <DatePicker
                                            selected={editData.test_date}
                                            onChange={handleDateChange}
                                            dateFormat="yyyy-MM-dd"
                                        />
                                    </td>
                                    <td><input name="notes" value={editData.notes || ''} onChange={handleInputChange} /></td>
                                    <td>
                                        <button onClick={handleSaveClick} style={{ color: 'green' }}>Save</button>
                                        <button onClick={handleCancelClick} style={{ marginLeft: '10px', color: 'red' }}>Cancel</button>
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td>{testResult.emitter_id}</td>
                                    <td>{testResult.type}</td>
                                    <td>{testResult.driver_type}</td>
                                    <td>{formatNumber(testResult.measured_cct, 0)}</td>
                                    <td>{formatNumber(testResult.measured_ra, 1)}</td>
                                    <td>{formatNumber(testResult.measured_r9, 1)}</td>
                                    <td>{formatNumber(testResult.measured_duv, 4)}</td>
                                    <td>{testResult.measured_lux || 'N/A'}</td>
                                    <td>{formatNumber(testResult.distance_tested, 1)}</td>
                                    <td>{testResult.test_date ? new Date(testResult.test_date).toLocaleDateString() : 'N/A'}</td>
                                    <td>{testResult.test_notes !== undefined && testResult.test_notes !== null ? testResult.test_notes : 'N/A'}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(testResult)}>Edit</button>
                                        <button onClick={() => handleDeleteClick(testResult.test_id)} style={{ marginLeft: '10px', color: 'red' }}>Delete</button>
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default FlashlightEmitterTestsPage;
