import axios from 'axios';

const API_URL = 'https://blackumbra.com/api/battery-assignments';
const AUTH_TOKEN = 'Your-Secret-Token';

// Set up Axios with base configuration
const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${AUTH_TOKEN}`,
    }
});

export const fetchAssignments = async () => {
    try {
        const response = await axiosInstance.get('/');
        return response.data;
    } catch (err) {
        console.error('Error fetching assignments:', err);
        throw err;
    }
};

export const fetchOtherAssignments = async () => {
    try {
        const response = await axiosInstance.get('/other-assignments');
        return response.data;
    } catch (err) {
        console.error('Error fetching other assignments:', err);
        throw err;
    }
};

export const fetchUnassignedBatteries = async () => {
    try {
        const response = await axiosInstance.get('/unassigned-batteries'); // Correct API endpoint
        return response.data;
    } catch (err) {
        console.error('Error fetching unassigned batteries:', err.response?.data || err);
        throw err;
    }
};


export const removeBatteryAssignment = async (flashlightId, batterySlot) => {
    try {
        const response = await axiosInstance.delete(`/${flashlightId}/remove-battery`, {
            data: { battery_slot: batterySlot }
        });
        return response.data;
    } catch (err) {
        console.error('Error removing battery assignment:', err);
        throw err;
    }
};

export const removeOtherAssignment = async (assignmentId) => {
    try {
        const response = await axiosInstance.delete(`/other-assignments/${assignmentId}`);
        return response.data;
    } catch (err) {
        console.error('Error removing other assignment:', err);
        throw err;
    }
};

export const updateOtherAssignment = async (assignmentId, updatedData) => {
    try {
        const response = await axiosInstance.put(`/other-assignments/${assignmentId}`, updatedData);
        return response.data;
    } catch (err) {
        console.error('Error updating other assignment:', err);
        throw err;
    }
};

export const fetchOtherAssignmentById = async (assignmentId) => {
    try {
        const response = await axiosInstance.get(`/other-assignments/${assignmentId}`);
        return response.data;
    } catch (err) {
        console.error('Error fetching other assignment by ID:', err);
        throw err;
    }
};
