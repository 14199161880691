// src/components/ChargeSessionsSection.js
import React from 'react';
import ChargeSessionsTable from './ChargeSessionsTable'; // Import the ChargeSessionsTable component

function ChargeSessionsSection({ activeTab, batteries, batteryTypes }) { // Add batteryTypes to props
    if (activeTab !== 'charge-sessions') return null;

    return (
        <div>
            <h2>Charge Sessions</h2>
            <ChargeSessionsTable 
                batteries={batteries} 
                batteryTypes={batteryTypes} // Pass the batteryTypes prop to ChargeSessionsTable
            />
        </div>
    );
}

export default ChargeSessionsSection;
