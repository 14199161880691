// src/services/batteryService.js
import axios from 'axios';

// API URLs and Auth Token
const API_URL_BATTERIES = 'https://blackumbra.com/api/batteries';
const API_URL_BATTERY_TYPES = 'https://blackumbra.com/api/battery-types';
const AUTH_TOKEN = 'Your-Secret-Token'; // Replace with your actual token

// Create axios instances for batteries and battery types
const axiosInstance = axios.create({
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${AUTH_TOKEN}`
    }
});

// Fetch all batteries and battery types in parallel
export const fetchBatteryInfo = () => {
    const batteriesPromise = axiosInstance.get(API_URL_BATTERIES);
    const batteryTypesPromise = axiosInstance.get(API_URL_BATTERY_TYPES);

    return Promise.all([batteriesPromise, batteryTypesPromise])
        .then(([batteriesResponse, batteryTypesResponse]) => ({
            batteries: batteriesResponse.data,
            batteryTypes: batteryTypesResponse.data
        }))
        .catch(error => {
            console.error('Error fetching battery information:', error);
            throw error;
        });
};

// Fetch batteries only
export const fetchBatteries = () => {
    return axiosInstance.get(API_URL_BATTERIES)
        .then(response => response.data)
        .catch(error => {
            console.error('Error fetching batteries:', error);
            throw error;
        });
};

// Fetch battery types only
export const fetchBatteryTypes = () => {
    return axiosInstance.get(API_URL_BATTERY_TYPES)
        .then(response => response.data)
        .catch(error => {
            console.error('Error fetching battery types:', error);
            throw error;
        });
};

// Fetch battery details by batteryId
export const fetchBatteryDetails = (batteryId) => {
    return axiosInstance.get(`${API_URL_BATTERIES}/${batteryId}`)
        .then(response => response.data)
        .catch(error => {
            console.error(`Error fetching battery details for ID ${batteryId}:`, error);
            throw error;
        });
};

// Update a battery
export const updateBattery = (id, batteryData) => {
    return axiosInstance.put(`${API_URL_BATTERIES}/${id}`, batteryData)
        .then(response => response.data)
        .catch(error => {
            console.error('Error updating battery:', error);
            throw error;
        });
};

// Update a battery's type
export const updateBatteryType = (id, typeId) => {
    return axiosInstance.put(`${API_URL_BATTERIES}/${id}/type`, { type_id: typeId })
        .then(response => response.data)
        .catch(error => {
            console.error(`Error updating battery type for ID ${id}:`, error);
            throw error;
        });
};

// Delete a battery
export const deleteBattery = (id) => {
    return axiosInstance.delete(`${API_URL_BATTERIES}/${id}`)
        .then(response => response.data)
        .catch(error => {
            console.error('Error deleting battery:', error);
            throw error;
        });
};
