import React, { useState } from 'react';

function EmittersTable({ emitters, onEditEmitter, onDeleteEmitter }) {
    if (!Array.isArray(emitters)) {
        return <div>Error: Data is not available or in the wrong format</div>;
    }

    const [sortConfig, setSortConfig] = useState({ key: 'type', direction: 'ascending' });
    const [searchTerm, setSearchTerm] = useState('');
    const [emitterToDelete, setEmitterToDelete] = useState(null);

    const sortedEmitters = () => {
        let sortableEmitters = [...emitters];
        if (sortConfig !== null) {
            sortableEmitters.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableEmitters;
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const confirmDelete = (emitter) => {
        setEmitterToDelete(emitter);
    };

    const handleDeleteConfirmed = () => {
        onDeleteEmitter(emitterToDelete.id);
        setEmitterToDelete(null);
    };

    const handleDeleteCanceled = () => {
        setEmitterToDelete(null);
    };

    const filteredEmitters = sortedEmitters().filter((emitter) => {
        return (
            Object.values(emitter).some((value) =>
                value ? value.toString().toLowerCase().includes(searchTerm.toLowerCase()) : false
            ) ||
            (emitter.flashlight &&
                Object.values(emitter.flashlight).some((value) =>
                    value ? value.toString().toLowerCase().includes(searchTerm.toLowerCase()) : false
                ))
        );
    });

    return (
        <div>
            <h2>Emitters</h2>
            <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearch}
                style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
            />
            {/* Display count of filtered results */}
            <p>Showing {filteredEmitters.length} of {emitters.length} emitters</p>
            <table style={{ width: '100%', border: '1px solid black' }}>
                <thead>
                    <tr>
                        <th onClick={() => requestSort('type')}>
                            Type {sortConfig.key === 'type' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => requestSort('driver_type')}>
                            Driver Type {sortConfig.key === 'driver_type' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => requestSort('max_lumens')}>
                            Max Lumens {sortConfig.key === 'max_lumens' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => requestSort('max_throw')}>
                            Max Throw {sortConfig.key === 'max_throw' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => requestSort('stated_cct')}>
                            Stated CCT {sortConfig.key === 'stated_cct' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => requestSort('notes')}>
                            Notes {sortConfig.key === 'notes' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => requestSort('flashlight')}>
                            Flashlight {sortConfig.key === 'flashlight' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                        </th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredEmitters.length > 0 ? (
                        filteredEmitters.map((emitter) => (
                            <tr key={emitter.id}>
                                <td>{emitter.type}</td>
                                <td>{emitter.driver_type}</td>
                                <td>{emitter.max_lumens}</td>
                                <td>{emitter.max_throw}</td>
                                <td>{emitter.stated_cct}</td>
                                <td>{emitter.notes}</td>
                                <td>
                                    {emitter.flashlight ? (
                                        `${emitter.flashlight.make} ${emitter.flashlight.model} - ${emitter.flashlight.nickname || 'No nickname'} (${emitter.flashlight.headstamp || 'No headstamp'})`
                                    ) : (
                                        'No flashlight'
                                    )}
                                </td>
                                <td>
                                    <button onClick={() => onEditEmitter(emitter)}>Edit</button>
                                    <button onClick={() => confirmDelete(emitter)} style={{ marginLeft: '10px', color: 'red' }}>Delete</button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="8">No emitters available</td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Confirmation dialog for delete action */}
            {emitterToDelete && (
                <div className="confirmation-dialog" style={{ marginTop: '20px', border: '1px solid red', padding: '10px' }}>
                    <p>Are you sure you want to delete the emitter '{emitterToDelete.type}'?</p>
                    <button onClick={handleDeleteConfirmed} style={{ marginRight: '10px', color: 'green' }}>Yes</button>
                    <button onClick={handleDeleteCanceled} style={{ color: 'red' }}>No</button>
                </div>
            )}
        </div>
    );
}

export default EmittersTable;
