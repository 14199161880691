// src/services/emitterService.js
import axios from 'axios';

const API_URL = 'https://blackumbra.com/api/emitters';
const AUTH_TOKEN = 'Your-Secret-Token';

// Fetch all emitters
export const fetchEmitters = () => {
    return axios.get(API_URL, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${AUTH_TOKEN}`
        }
    })
    .then(response => response.data)
    .catch(error => {
        console.error('Error fetching emitters:', error);
        throw error;
    });
};

// Create a new emitter
export const createEmitter = (emitterData) => {
    return axios.post(API_URL, emitterData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${AUTH_TOKEN}`
        }
    })
    .then(response => response.data)
    .catch(error => {
        console.error('Error creating emitter:', error);
        throw error;
    });
};

// Update an existing emitter
export const updateEmitter = (emitterId, emitterData) => {
    return axios.put(`${API_URL}/${emitterId}`, emitterData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${AUTH_TOKEN}`
        }
    })
    .then(response => response.data)
    .catch(error => {
        console.error('Error updating emitter:', error);
        throw error;
    });
};

// Delete an emitter
export const deleteEmitter = (emitterId) => {
    return axios.delete(`${API_URL}/${emitterId}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${AUTH_TOKEN}`
        }
    })
    .then(response => response.status === 204)
    .catch(error => {
        console.error('Error deleting emitter:', error);
        throw error;
    });
};