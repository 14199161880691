import React, { useState } from 'react';
import BatteryTable from './BatteryTable';

function BatteriesSection({ batteryTypes, onBatteryAdded }) {
    const [refreshTrigger, setRefreshTrigger] = useState(false);

    const handleBatteryAdded = () => {
        console.log('Battery added in BatteriesSection...');
        onBatteryAdded();
        setRefreshTrigger(prev => !prev); // Trigger table refresh
    };

    console.log('Rendering BatteriesSection...', { batteryTypes });

    return (
        <div>
            <h1>Batteries</h1>
            <BatteryTable refreshTrigger={refreshTrigger} />
        </div>
    );
}

export default BatteriesSection;
