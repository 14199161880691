import axios from 'axios';

// Base API URL and Authorization token
const API_URL = 'https://blackumbra.com/api/flashlights';
const AUTH_TOKEN = 'Bearer Your-Secret-Token'; // Ensure the 'Bearer ' prefix is included

// Common headers object
const headers = {
    'Content-Type': 'application/json',
    'Authorization': AUTH_TOKEN
};

// Fetch all flashlights
export const fetchFlashlights = () => {
    return axios.get(API_URL, { headers })
        .then(response => response.data)
        .catch(error => {
            console.error('Error fetching flashlights:', error);
            throw error;
        });
};

// Add a new flashlight
export const addFlashlight = (flashlight) => {
    return axios.post(API_URL, flashlight, { headers })
        .then(response => response.data)
        .catch(error => {
            console.error('Error adding flashlight:', error);
            throw error;
        });
};

// Fetch a single flashlight by ID
export const fetchFlashlightById = (flashlightId) => {
    return axios.get(`${API_URL}/${flashlightId}`, { headers })
        .then(response => response.data)
        .catch(error => {
            console.error(`Error fetching flashlight ID ${flashlightId}:`, error);
            throw error;
        });
};

// Delete a flashlight by ID
export const deleteFlashlight = (flashlightId) => {
    return axios.delete(`${API_URL}/${flashlightId}`, { headers })
        .then(response => response.status === 204)
        .catch(error => {
            console.error(`Error deleting flashlight ID ${flashlightId}:`, error);
            throw error;
        });
};
