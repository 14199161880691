import React, { useState } from 'react';
import { removeBatteryAssignment } from '../services/assignmentService';

function FlashlightBatteryAssignmentTable({ assignments, flashlights, batteries, batteryTypes, onAssignmentRemoved, searchTerm }) {
    const [sortConfig, setSortConfig] = useState({ key: 'flashlight_make', direction: 'ascending' });

    // Sorting logic
    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getBatteryInfo = (batteryBarcode) => {
        const battery = batteries.find(b => b.barcode === batteryBarcode);
        if (!battery) return 'Unknown Battery';

        const batteryType = batteryTypes.find(type => type.id === battery.type_id);
        if (!batteryType) return 'Unknown Type';

        return `${batteryType.brand || ''} ${batteryType.model || ''} ${batteryType.stated_capacity ? batteryType.stated_capacity + 'mAh' : ''}`;
    };

    const getFlashlightInfo = (assignment) => {
        const flashlight = flashlights.find(f => f.id === assignment.flashlight_id);
        if (!flashlight) return 'Unknown Flashlight';

        const { make, model, nickname } = flashlight;
        const extraInfo = nickname ? ` (${nickname})` : '';
        return `${make} ${model || ''}${extraInfo}`;
    };

    // Handle removal of the battery assignment
    const handleRemoveAssignment = (flashlightId, batterySlot) => {
        removeBatteryAssignment(flashlightId, batterySlot)
            .then(() => {
                // Optionally, trigger any callback passed via props
                if (onAssignmentRemoved) {
                    onAssignmentRemoved(flashlightId, batterySlot);
                }
                alert('Assignment removed successfully!');
            })
            .catch((error) => {
                console.error('Error removing assignment:', error);
                alert('Failed to remove assignment.');
            });
    };

    // Filter assignments based on search input
    const filteredAssignments = assignments.filter((assignment) => {
        const batteryInfo = getBatteryInfo(assignment.battery_barcode).toLowerCase();
        const flashlightInfo = getFlashlightInfo(assignment).toLowerCase();
        const barcode = assignment.battery_barcode ? assignment.battery_barcode.toLowerCase() : '';
        return (
            batteryInfo.includes(searchTerm.toLowerCase()) ||
            flashlightInfo.includes(searchTerm.toLowerCase()) ||
            barcode.includes(searchTerm.toLowerCase())
        );
    });

    return (
        <div>
            <h2>Flashlight Battery Assignments</h2>
            <p>Showing {filteredAssignments.length} of {assignments.length} assignments</p>

            <table style={{ width: '100%', border: '1px solid black' }}>
                <thead>
                    <tr>
                        <th onClick={() => handleSort('flashlight_make')}>
                            Flashlight {sortConfig.key === 'flashlight_make' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                        </th>
                        <th onClick={() => handleSort('battery_brand')}>
                            Battery {sortConfig.key === 'battery_brand' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                        </th>
                        <th onClick={() => handleSort('battery_barcode')}>
                            Barcode {sortConfig.key === 'battery_barcode' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                        </th>
                        <th onClick={() => handleSort('battery_slot')}>
                            Slot {sortConfig.key === 'battery_slot' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                        </th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredAssignments.length > 0 ? (
                        filteredAssignments.map((assignment, index) => (
                            <tr key={index}>
                                <td>{getFlashlightInfo(assignment)}</td>
                                <td>{getBatteryInfo(assignment.battery_barcode)}</td>
                                <td>{assignment.battery_barcode || 'N/A'}</td>
                                <td>{assignment.battery_slot || 'N/A'}</td>
                                <td>
                                    <button onClick={() => handleRemoveAssignment(assignment.flashlight_id, assignment.battery_slot)}>
                                        Remove Assignment
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5">No assignments available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default FlashlightBatteryAssignmentTable;
