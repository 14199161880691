import React, { useState, useEffect } from 'react';
import { fetchUnassignedBatteries, fetchOtherAssignments, fetchAssignments } from '../services/assignmentService'; // Import the new serviceconst AUTH_TOKEN = 'Your-Secret-Token';

const AUTH_TOKEN = 'Your-Secret-Token';

function AssignBatteryForm({ flashlights = [], onAssignmentChanged, refreshTrigger }) {
    const [selectedFlashlight, setSelectedFlashlight] = useState('');
    const [selectedBattery, setSelectedBattery] = useState('');
    const [selectedSlot, setSelectedSlot] = useState(1);
    const [assignmentType, setAssignmentType] = useState('flashlight');
    const [objectName, setObjectName] = useState('');
    const [notes, setNotes] = useState('');
    const [unassignedBatteries, setUnassignedBatteries] = useState([]); // Initialize as empty array
    const [assignedBatteries, setAssignedBatteries] = useState([]); // Initialize as empty array

    useEffect(() => {
        // Fetch unassigned batteries when component mounts or refreshTrigger changes
        fetchUnassignedBatteries()
            .then(data => {
                if (Array.isArray(data)) {
                    setUnassignedBatteries(data); // Ensure data is an array
                } else {
                    console.error('Unassigned batteries API did not return an array:', data);
                    setUnassignedBatteries([]); // Default to an empty array
                }
            })
            .catch(error => console.error('Error fetching unassigned batteries:', error));

        // Fetch assignments for flashlights and other items
        Promise.all([fetchAssignments(), fetchOtherAssignments()])
            .then(([flashlightAssignments, otherAssignments]) => {
                // Combine assigned battery IDs from both assignments
                const flashlightAssignedBatteryIds = flashlightAssignments.map(a => a.battery_id);
                const otherAssignedBatteryIds = otherAssignments.map(a => a.battery_id);

                const allAssignedBatteryIds = [...new Set([...flashlightAssignedBatteryIds, ...otherAssignedBatteryIds])];

                // Store assigned battery IDs in state
                setAssignedBatteries(allAssignedBatteryIds.map(id => id.toString())); // Ensure all IDs are strings for comparison
            })
            .catch(error => console.error('Error fetching assignments:', error));
    }, [refreshTrigger]);

    // Filter unassigned batteries to exclude those assigned to flashlights and other items
    const getFilteredUnassignedBatteries = () => {
        if (!Array.isArray(unassignedBatteries)) {
            console.error('unassignedBatteries is not an array', unassignedBatteries);
            return [];
        }

        return unassignedBatteries
            .filter(battery => !assignedBatteries.includes(battery.id.toString())) // Filter out assigned batteries
            .sort((a, b) => b.barcode.localeCompare(a.barcode)); // Sort by barcode in descending order
    };

    // Function to display flashlight name with nickname and headstamp (if available)
    const formatFlashlightName = (flashlight) => {
        const { make, model, nickname, headstamp } = flashlight;

        // Base name: "Make Model"
        let displayName = `${make} ${model}`;

        // Add nickname in parentheses if it exists
        if (nickname) {
            displayName += ` (${nickname})`;
        }

        // Add headstamp in square brackets if it exists
        if (headstamp) {
            displayName += ` [${headstamp}]`;
        }

        return displayName;
    };

    // Function to display the battery with barcode first, then make and model
    const formatBatteryName = (battery) => {
        const { barcode, brand, model } = battery;
        return `[${barcode}] ${brand} ${model}`;
    };

    // Sort flashlights alphabetically by the displayed name (nickname or make/model)
    const getSortedFlashlights = () => {
        return [...flashlights].sort((a, b) => {
            const nameA = formatFlashlightName(a).toLowerCase();
            const nameB = formatFlashlightName(b).toLowerCase();
            return nameA.localeCompare(nameB);
        });
    };

    const handleAssign = (e) => {
        e.preventDefault();
        if (!selectedBattery) {
            alert('Please select a battery.');
            return;
        }

        const requestBody = { battery_id: selectedBattery };

        if (assignmentType === 'flashlight') {
            if (!selectedFlashlight) {
                alert('Please select a flashlight.');
                return;
            }
            requestBody.flashlight_id = selectedFlashlight;
            requestBody.battery_slot = selectedSlot;
        } else {
            if (!objectName) {
                alert('Please specify the object.');
                return;
            }
            requestBody.object_name = objectName;
            requestBody.notes = notes;
        }

        const url = assignmentType === 'flashlight'
            ? `https://blackumbra.com/api/battery-assignments/${selectedFlashlight}/assign-battery`
            : 'https://blackumbra.com/api/battery-assignments/assign-battery';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AUTH_TOKEN}`,
            },
            body: JSON.stringify(requestBody),
        })
        .then(response => response.json())
        .then(() => {
            alert('Battery assigned successfully!');
            setSelectedFlashlight('');
            setSelectedBattery('');
            setSelectedSlot(1);
            setObjectName('');
            setNotes('');
            onAssignmentChanged();
        })
        .catch(error => {
            console.error('Error assigning battery:', error);
            alert('Error assigning battery');
        });
    };

    return (
        <form onSubmit={handleAssign}>
            <label>Assignment Type:</label>
            <select value={assignmentType} onChange={e => setAssignmentType(e.target.value)}>
                <option value="flashlight">Flashlight</option>
                <option value="other">Other</option>
            </select>

            {assignmentType === 'flashlight' && (
                <>
                    <label>Select Flashlight:</label>
                    <select name="flashlight_id" value={selectedFlashlight} onChange={e => setSelectedFlashlight(e.target.value)}>
                        <option value="">-- Select Flashlight --</option>
                        {getSortedFlashlights().map(flashlight => (
                            <option key={flashlight.id} value={flashlight.id}>
                                {formatFlashlightName(flashlight)}
                            </option>
                        ))}
                    </select>

                    <label>Battery Slot:</label>
                    <input
                        type="number"
                        name="battery_slot"
                        min="1"
                        value={selectedSlot}
                        onChange={(e) => setSelectedSlot(e.target.value)}
                        placeholder="Slot Number"
                    />
                </>
            )}

            {assignmentType === 'other' && (
                <>
                    <label>Object Name:</label>
                    <input
                        type="text"
                        name="object_name"
                        value={objectName}
                        onChange={e => setObjectName(e.target.value)}
                        placeholder="Enter object name"
                    />

                    <label>Notes:</label>
                    <textarea
                        name="notes"
                        value={notes}
                        onChange={e => setNotes(e.target.value)}
                        placeholder="Enter additional notes"
                    />
                </>
            )}

            <label>Select Battery:</label>
            <select name="battery_id" value={selectedBattery} onChange={e => setSelectedBattery(e.target.value)}>
                <option value="">-- Select Battery --</option>
                {getFilteredUnassignedBatteries().map(battery => (
                    <option key={battery.id} value={battery.id}>
                        {formatBatteryName(battery)} {/* Use formatted battery name */}
                    </option>
                ))}
            </select>

            <button type="submit">Assign Battery</button>
        </form>
    );
}

export default AssignBatteryForm;
