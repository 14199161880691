import React, { useState } from 'react';
import axios from 'axios';

const AUTH_TOKEN = 'Your-Secret-Token'; // Replace with your actual token

function AddEmitterTestResultsForm({ flashlights, onEmitterTestAdded }) {
    const [flashlightId, setFlashlightId] = useState('');
    const [emitters, setEmitters] = useState([]);
    const [emitter, setEmitter] = useState({
        emitter_id: '',
        measured_cct: '',
        measured_ra: '',
        measured_r9: '',
        measured_duv: '',
        measured_lux: '',
        distance_tested: '',
        flicker: '',
        notes: '' // Add notes to the emitter state
    });

    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    // Fetch emitters for selected flashlight
    const fetchEmitters = (flashlightId) => {
        console.log(`Fetching emitters for flashlight ID: ${flashlightId}`);
        axios.get(`https://blackumbra.com/api/flashlights/${flashlightId}/emitters`, {
            headers: {
                'Authorization': `Bearer ${AUTH_TOKEN}`
            }
        })
        .then(response => {
            console.log('Emitters fetched:', response.data);

            // Remove duplicate emitters based on id
            const uniqueEmitters = response.data.filter((em, index, self) =>
                index === self.findIndex((e) => e.id === em.id)
            );

            setEmitters(uniqueEmitters);
        })
        .catch(err => {
            console.error('Error fetching emitters:', err);
            setError('Error fetching emitters');
        });
    };

    // Handle flashlight selection
    const handleFlashlightChange = (e) => {
        const selectedFlashlightId = e.target.value;
        console.log("Flashlight Change:", selectedFlashlightId);
        setFlashlightId(selectedFlashlightId);
        setEmitters([]);  // Clear emitters when changing flashlights
        setEmitter({ ...emitter, emitter_id: '' }); // Clear emitter_id in the emitter state
        if (selectedFlashlightId) {
            fetchEmitters(selectedFlashlightId); // Fetch emitters for the selected flashlight
        }
    };

    // Handle emitter selection
    const handleEmitterChange = (e) => {
        const { name, value } = e.target;
        setEmitter(prev => ({ ...prev, [name]: value }));
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        // Check if emitter_id is selected before submitting
        if (!emitter.emitter_id || emitter.emitter_id === '') {
            setError('Please select an emitter.');
            return;
        }

        // Submit the emitter test result data
        axios.post('https://blackumbra.com/api/emitter-test-results', emitter, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AUTH_TOKEN}`
            }
        })
        .then(response => {
            setMessage('Emitter test results added successfully');
            setEmitter({
                emitter_id: '',
                measured_cct: '',
                measured_ra: '',
                measured_r9: '',
                measured_duv: '',
                measured_lux: '',
                distance_tested: '',
                flicker: '',
                notes: '' // Reset notes
            });
            onEmitterTestAdded();  // Trigger refresh of the table after submission
        })
        .catch(err => {
            console.error('Error adding emitter test results:', err);
            setError('Error adding emitter test results');
        });
    };

    // Function to get sorted flashlights
    const getSortedFlashlights = () => {
        return flashlights.slice().sort((a, b) => {
            const nameA = a.nickname ? a.nickname.toLowerCase() : `${a.make} ${a.model}`.toLowerCase();
            const nameB = b.nickname ? b.nickname.toLowerCase() : `${b.make} ${b.model}`.toLowerCase();
            return nameA.localeCompare(nameB);
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>Add Emitter Test Results</h2>

            <label>Flashlight:</label>
            <select name="flashlight_id" value={flashlightId} onChange={handleFlashlightChange}>
                <option value="">Select Flashlight</option>
                {getSortedFlashlights().map(flashlight => (
                    <option key={flashlight.id} value={flashlight.id}>
                        {flashlight.nickname
                            ? flashlight.nickname
                            : `${flashlight.make} ${flashlight.model} ${flashlight.headstamp ? `(Headstamp: ${flashlight.headstamp})` : `(ID: ${flashlight.id})`}`}
                    </option>
                ))}
            </select>

            {flashlightId && emitters.length > 0 && (
                <>
                    <label>Emitter:</label>
                    <select name="emitter_id" value={emitter.emitter_id} onChange={handleEmitterChange}>
                        <option value="">Select Emitter</option>
                        {emitters.map((em, index) => (
                            <option key={`${em.id}-${index}`} value={em.id}>
                                {em.type} (Driver: {em.driver_type})
                            </option>
                        ))}
                    </select>
                </>
            )}

            <label>Measured CCT:</label>
            <input
                name="measured_cct"
                type="number"
                value={emitter.measured_cct}
                onChange={handleEmitterChange}
                placeholder="Measured CCT"
                step="1"
                min="0"
            />

            <label>Measured Ra:</label>
            <input name="measured_ra" type="number" value={emitter.measured_ra} placeholder="Measured Ra" onChange={handleEmitterChange} />

            <label>Measured R9:</label>
            <input name="measured_r9" type="number" value={emitter.measured_r9} placeholder="Measured R9" onChange={handleEmitterChange} />

            <label>Measured DUV:</label>
            <input name="measured_duv" type="number" value={emitter.measured_duv} placeholder="Measured DUV" onChange={handleEmitterChange} />

            <label>Measured Lux:</label>
            <input name="measured_lux" type="number" value={emitter.measured_lux} placeholder="Measured Lux" onChange={handleEmitterChange} />

            <label>Distance Tested (m):</label>
            <input name="distance_tested" type="number" value={emitter.distance_tested} placeholder="Distance Tested" onChange={handleEmitterChange} />

            <label>Flicker (%):</label>
            <input
                name="flicker"
                type="number"
                step="0.01"
                value={emitter.flicker || ''}
                onChange={handleEmitterChange}
                placeholder="Flicker percentage"
            />

            <label>Notes:</label>
            <textarea
                name="notes"
                value={emitter.notes}
                onChange={handleEmitterChange}
                placeholder="Additional notes or observations"
                rows="3"
            />

            <button type="submit">Submit</button>
            {message && <p style={{ color: 'green' }}>{message}</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </form>
    );
}

export default AddEmitterTestResultsForm;
