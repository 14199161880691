import React, { useState, useEffect, memo } from 'react';
import ChargeSessionTable from './ChargeSessionTable';
import { fetchBatteries, updateBattery, deleteBattery } from '../services/batteryService';

function BatteryTable({ refreshTrigger }) {
    const [batteries, setBatteries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedBatteryId, setSelectedBatteryId] = useState(null);
    const [editableRow, setEditableRow] = useState(null);
    const [editData, setEditData] = useState({});
    const [sortConfig, setSortConfig] = useState({ key: 'barcode', direction: 'ascending' });
    const [searchQuery, setSearchQuery] = useState(''); // Search query state

    // Fetch batteries from the server
    const fetchBatteryData = () => {
        setLoading(true);
        fetchBatteries()
            .then(data => {
                setBatteries(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching batteries:', error);
                setError('Failed to load batteries.');
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchBatteryData();
    }, [refreshTrigger]);

    const formatDateForDisplay = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        return `${month}-${day}-${year}`; // MM-DD-YYYY format
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditData({ ...editData, [name]: value === '' ? null : value });
    };

    const handleEditClick = (battery) => {
        const formattedDateAcquired = battery.date_acquired ? new Date(battery.date_acquired).toISOString().split('T')[0] : '';
        const formattedLastTestDate = battery.last_test_date ? new Date(battery.last_test_date).toISOString().split('T')[0] : '';

        setEditableRow(battery.id);
        setEditData({
            ...battery,
            date_acquired: formattedDateAcquired,
            last_test_date: formattedLastTestDate || '' // Set to empty string if null
        });
    };

    const handleSaveClick = () => {
        const updateData = {
            barcode: editData.barcode,
            date_acquired: editData.date_acquired,
            last_test_date: editData.last_test_date || null, // Set to null if empty
            notes: editData.notes
        };

        updateBattery(editableRow, updateData)
            .then(() => {
                alert('Battery updated successfully!');
                setEditableRow(null);
                setEditData({});
                fetchBatteryData(); // Refresh the battery list after update
            })
            .catch(error => {
                console.error('Error updating battery:', error);
                alert(`Error updating battery: ${error.message}`);
            });
    };

    const handleCancelClick = () => {
        setEditableRow(null);
        setEditData({});
    };

    const handleDeleteClick = (batteryId) => {
        if (window.confirm('Are you sure you want to delete this battery? This action cannot be undone.')) {
            deleteBattery(batteryId)
                .then(() => {
                    alert('Battery deleted successfully!');
                    fetchBatteryData(); // Refresh the battery list after deletion
                })
                .catch(error => {
                    console.error('Error deleting battery:', error);
                    alert(`Error deleting battery: ${error.message}`);
                });
        }
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getSortIndicator = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '▲' : '▼';
        }
        return '';
    };

    const sortedBatteries = [...batteries]
        .filter((battery) => {
            // Filter the batteries based on the search query (case insensitive)
            return (
                battery.barcode.toLowerCase().includes(searchQuery.toLowerCase()) ||
                battery.brand.toLowerCase().includes(searchQuery.toLowerCase()) ||
                battery.model.toLowerCase().includes(searchQuery.toLowerCase()) ||
                battery.type.toLowerCase().includes(searchQuery.toLowerCase()) ||
                battery.chemistry.toLowerCase().includes(searchQuery.toLowerCase()) ||
                (battery.notes && battery.notes.toLowerCase().includes(searchQuery.toLowerCase()))
            );
        })
        .sort((a, b) => {
            if (a[sortConfig.key] === null) return 1;
            if (b[sortConfig.key] === null) return -1;
            if (a[sortConfig.key] === b[sortConfig.key]) return 0;
            return (a[sortConfig.key] > b[sortConfig.key] ? 1 : -1) * (sortConfig.direction === 'ascending' ? 1 : -1);
        });

    if (loading) {
        return <p>Loading batteries...</p>;
    }

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    if (selectedBatteryId) {
        return <ChargeSessionTable batteryId={selectedBatteryId} onBackClick={() => setSelectedBatteryId(null)} />;
    }

    return (
        <div>
            <h2>Batteries</h2>
            {/* Display count of filtered results */}
            <p>Showing {sortedBatteries.length} of {batteries.length} results</p>
            {/* Search input field */}
            <input
                type="text"
                placeholder="Search by barcode, brand, model, type, or notes"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
            />
            <table style={{ width: '100%', border: '1px solid black' }}>
                <thead>
                    <tr>
                        <th onClick={() => handleSort('barcode')}>Barcode {getSortIndicator('barcode')}</th>
                        <th onClick={() => handleSort('brand')}>Brand {getSortIndicator('brand')}</th>
                        <th onClick={() => handleSort('model')}>Model {getSortIndicator('model')}</th>
                        <th onClick={() => handleSort('type')}>Type {getSortIndicator('type')}</th>
                        <th onClick={() => handleSort('chemistry')}>Chemistry {getSortIndicator('chemistry')}</th>
                        <th onClick={() => handleSort('date_acquired')}>Date Acquired {getSortIndicator('date_acquired')}</th>
                        <th onClick={() => handleSort('last_test_date')}>Last Test Date {getSortIndicator('last_test_date')}</th>
                        <th onClick={() => handleSort('notes')}>Notes {getSortIndicator('notes')}</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedBatteries.map((battery) => (
                        <tr key={battery.id}>
                            {editableRow === battery.id ? (
                                <>
                                    <td><input name="barcode" value={editData.barcode} onChange={handleInputChange} /></td>
                                    <td>{battery.brand || 'N/A'}</td>
                                    <td>{battery.model || 'N/A'}</td>
                                    <td>{battery.type || 'N/A'}</td>
                                    <td>{battery.chemistry || 'N/A'}</td>
                                    <td><input name="date_acquired" type="date" value={editData.date_acquired} onChange={handleInputChange} /></td>
                                    <td><input name="last_test_date" type="date" value={editData.last_test_date || ''} onChange={handleInputChange} /></td>
                                    <td><input name="notes" value={editData.notes} onChange={handleInputChange} /></td>
                                    <td>
                                        <button onClick={handleSaveClick} style={{ color: 'green' }}>Save</button>
                                        <button onClick={handleCancelClick} style={{ marginLeft: '10px', color: 'red' }}>Cancel</button>
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td>{battery.barcode}</td>
                                    <td>{battery.brand || 'N/A'}</td>
                                    <td>{battery.model || 'N/A'}</td>
                                    <td>{battery.type || 'N/A'}</td>
                                    <td>{battery.chemistry || 'N/A'}</td>
                                    <td>{formatDateForDisplay(battery.date_acquired)}</td>
                                    <td>{formatDateForDisplay(battery.last_test_date)}</td>
                                    <td>{battery.notes || 'No battery notes'}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(battery)}>Edit</button>
                                        <button onClick={() => setSelectedBatteryId(battery.id)} style={{ marginLeft: '10px' }}>View Charge Sessions</button>
                                        <button onClick={() => handleDeleteClick(battery.id)} style={{ marginLeft: '10px', color: 'red' }}>Delete</button>
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default memo(BatteryTable);
