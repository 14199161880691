import React, { useState, useEffect } from 'react';
import TabLayout from '../components/TabLayout';
import BatteriesSection from '../components/BatteriesSection';
import BatteryTypesSection from '../components/BatteryTypesSection';
import ChargeSessionsSection from '../components/ChargeSessionsSection';
import AddBatterySection from '../components/AddBatterySection'; // Import the AddBatterySection component
import { fetchBatteries, fetchBatteryTypes } from '../services/batteryService';
import '../components/TabLayout.css'; // Import the CSS for TabLayout

function BatteriesPage() {
    const [batteries, setBatteries] = useState([]);
    const [batteryTypes, setBatteryTypes] = useState([]);
    const [refreshTrigger, setRefreshTrigger] = useState(false);

    useEffect(() => {
        console.log('Fetching batteries and battery types...'); // Log to ensure the useEffect is triggered
        // Fetch Batteries
        fetchBatteries()
            .then(data => {
                console.log('Fetched Batteries:', data); // Debugging: Log fetched batteries
                setBatteries(data);
            })
            .catch(err => console.error('Error fetching batteries:', err));
    
        // Fetch Battery Types
        fetchBatteryTypes()
            .then(data => {
                console.log('Fetched Battery Types:', data); // Debugging: Log fetched battery types
                setBatteryTypes(data);
            })
            .catch(err => console.error('Error fetching battery types:', err));
    }, [refreshTrigger]); // Re-fetch when refreshTrigger changes
    
    const handleBatteryAdded = () => {
        console.log('Battery type added, refreshing data...'); // Log to confirm the function is called
        setRefreshTrigger(prev => !prev); // Toggle to refresh data
        console.log('Refresh Trigger State:', refreshTrigger); // Log the current state of refreshTrigger
    };    

    const tabs = [
        { id: 'batteries', label: 'Batteries' },
        { id: 'battery-types', label: 'Battery Types' },
        { id: 'charge-sessions', label: 'Charge Sessions' },
        { id: 'add-battery', label: 'Add Battery' } // Add the new tab here
    ];

    return (
        <div className="container dark-theme">
            <TabLayout tabs={tabs}>
                <BatteriesSection
                    activeTab="batteries"
                    batteries={batteries}
                    batteryTypes={batteryTypes}
                    onBatteryAdded={handleBatteryAdded}
                />
                <BatteryTypesSection
                    activeTab="battery-types"
                    batteryTypes={batteryTypes} // Pass updated batteryTypes as prop
                    onBatteryTypeAdded={handleBatteryAdded} // Trigger refresh on battery type add
                />
                <ChargeSessionsSection
                    activeTab="charge-sessions"
                    batteries={batteries} // Pass the batteries array
                    batteryTypes={batteryTypes} // Pass updated batteryTypes as prop
                    onChargeSessionAdded={handleBatteryAdded}
                />
                <AddBatterySection
                    activeTab="add-battery"
                    batteryTypes={batteryTypes} // Pass updated batteryTypes as prop
                    onBatteryAdded={handleBatteryAdded} // Pass the callback here
                    onBatteryTypeAdded={handleBatteryAdded} // Trigger refresh on battery type add
                />
            </TabLayout>
        </div>
    );
}

export default BatteriesPage;
