import React, { useState, useEffect } from 'react';

function AddEmitterForm({ onSaveEmitter, flashlights = [], editingEmitter, onCancelEdit }) {
    const [type, setType] = useState('');
    const [driverType, setDriverType] = useState('');
    const [maxLumens, setMaxLumens] = useState('');
    const [maxThrow, setMaxThrow] = useState('');
    const [statedCct, setStatedCct] = useState('');
    const [notes, setNotes] = useState('');
    const [flashlightId, setFlashlightId] = useState('');

    useEffect(() => {
        if (editingEmitter) {
            setType(editingEmitter.type || '');
            setDriverType(editingEmitter.driver_type || '');
            setMaxLumens(editingEmitter.max_lumens || '');
            setMaxThrow(editingEmitter.max_throw || '');
            setStatedCct(editingEmitter.stated_cct || '');
            setNotes(editingEmitter.notes || '');
            setFlashlightId(editingEmitter.flashlight_id || '');
        } else {
            clearForm();
        }
    }, [editingEmitter]);

    const clearForm = () => {
        setType('');
        setDriverType('');
        setMaxLumens('');
        setMaxThrow('');
        setStatedCct('');
        setNotes('');
        setFlashlightId('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const emitterData = {
            type,
            driver_type: driverType,
            max_lumens: maxLumens === '' ? null : parseInt(maxLumens, 10),
            max_throw: maxThrow === '' ? null : parseInt(maxThrow, 10),
            stated_cct: statedCct,
            notes,
            flashlight_id: flashlightId === '' ? null : parseInt(flashlightId, 10),
        };

        onSaveEmitter(emitterData);
        clearForm();
    };

    const handleCancel = () => {
        clearForm();
        onCancelEdit();
    };

    if (!flashlights || flashlights.length === 0) {
        return <div>Loading flashlights...</div>; // Display loading if flashlights are not available
    }

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>Flashlight:</label>
                <select
                    value={flashlightId}
                    onChange={(e) => setFlashlightId(e.target.value)}
                >
                    <option value="">Select a flashlight</option>
                    {flashlights
                        .sort((a, b) => a.make.localeCompare(b.make)) // Sort flashlights alphabetically
                        .map(flashlight => (
                            <option key={flashlight.id} value={flashlight.id}>
                                {`${flashlight.make} ${flashlight.model} - ${flashlight.nickname || ''} (${flashlight.headstamp || ''})`}
                            </option>
                        ))}
                </select>
            </div>
            <div>
                <label>Type:</label>
                <input
                    type="text"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>Driver Type:</label>
                <input
                    type="text"
                    value={driverType}
                    onChange={(e) => setDriverType(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>Max Lumens:</label>
                <input
                    type="number"
                    value={maxLumens}
                    onChange={(e) => setMaxLumens(e.target.value)}
                />
            </div>
            <div>
                <label>Max Throw:</label>
                <input
                    type="number"
                    value={maxThrow}
                    onChange={(e) => setMaxThrow(e.target.value)}
                />
            </div>
            <div>
                <label>Stated CCT:</label>
                <input
                    type="text"
                    value={statedCct}
                    onChange={(e) => setStatedCct(e.target.value)}
                />
            </div>
            <div>
                <label>Notes:</label>
                <textarea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                ></textarea>
            </div>
            <button type="submit">{editingEmitter ? 'Update' : 'Add'} Emitter</button>
            {editingEmitter && <button type="button" onClick={handleCancel}>Cancel</button>}
        </form>
    );
}

export default AddEmitterForm;
