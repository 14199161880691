import React from 'react';
import AddBatteryForm from './AddBatteryForm';

function AddBatterySection({ batteryTypes, onBatteryAdded }) {
    return (
        <div>
            <h2>Add Battery</h2>
            <AddBatteryForm batteryTypes={batteryTypes} onBatteryAdded={onBatteryAdded} />
        </div>
    );
}

export default AddBatterySection;
