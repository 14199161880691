import React, { useState } from 'react';
import './TabLayout.css';

function TabLayout({ tabs, children }) {
    const [activeTab, setActiveTab] = useState(tabs[0].id);

    // Filter the children to only render the active tab's content
    const activeTabContent = React.Children.toArray(children).find(
        (child) => child.props.activeTab === activeTab
    );

    return (
        <div>
            <div className="tabs">
                <div className="tabs-container"> {/* Container for centering */}
                    {tabs.map(tab => (
                        <button
                            key={tab.id}
                            className={tab.id === activeTab ? 'active' : ''}
                            onClick={() => setActiveTab(tab.id)}
                        >
                            {tab.label}
                        </button>
                    ))}
                </div>
            </div>
            <div className="tab-content">
                {activeTabContent}
            </div>
        </div>
    );
}

export default TabLayout;
