import React, { useState } from 'react';
import axios from 'axios';

const AUTH_TOKEN = 'Your-Secret-Token'; // Replace with your actual token

function AddBatteryTypeForm({ onBatteryTypeAdded }) {
  const [batteryType, setBatteryType] = useState({
    brand: '',
    model: '',
    type: '',
    nominal_voltage: '',
    cdr: '',
    stated_capacity: '',
    button_top: false,  // Add default values
    protected: false,   // Add default values
    chemistry: '',      // Add chemistry field
    notes: ''           // Add notes field
  });

  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    try {
        const response = await axios.post('https://blackumbra.com/api/battery-types', batteryType, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AUTH_TOKEN}`
            }
        });

        if (response.status === 201) {
            setMessage('Battery type added successfully');
            setBatteryType({
                brand: '',
                model: '',
                type: '',
                nominal_voltage: '',
                cdr: '',
                stated_capacity: '',
                button_top: false,
                protected: false,
                chemistry: '',
                notes: ''
            });

            if (onBatteryTypeAdded && typeof onBatteryTypeAdded === 'function') {
              console.log('Triggering onBatteryTypeAdded callback'); // Log to confirm
              onBatteryTypeAdded();  // This should trigger data refresh in the parent
          }
          
        } else {
            setError(response.data.message || 'Failed to add battery type');
        }
    } catch (err) {
        console.error('Error submitting battery type:', err);
        setError('Error: Failed to submit battery type.');
    }
};

  return (
    <form onSubmit={handleSubmit}>
      <input 
        name="brand" 
        value={batteryType.brand} 
        onChange={(e) => setBatteryType({...batteryType, brand: e.target.value})} 
        placeholder="Brand" 
      />
      <input 
        name="model" 
        value={batteryType.model} 
        onChange={(e) => setBatteryType({...batteryType, model: e.target.value})} 
        placeholder="Model" 
      />
      <input 
        name="type" 
        value={batteryType.type} 
        onChange={(e) => setBatteryType({...batteryType, type: e.target.value})} 
        placeholder="Type" 
      />
      <input 
        name="nominal_voltage" 
        type="number" 
        step="0.01" 
        value={batteryType.nominal_voltage} 
        onChange={(e) => setBatteryType({...batteryType, nominal_voltage: e.target.value})} 
        placeholder="Nominal Voltage" 
      />
      <input 
        name="cdr" 
        type="number" 
        value={batteryType.cdr} 
        onChange={(e) => setBatteryType({...batteryType, cdr: e.target.value})} 
        placeholder="CDR" 
      />
      <input 
        name="stated_capacity" 
        type="number" 
        value={batteryType.stated_capacity} 
        onChange={(e) => setBatteryType({...batteryType, stated_capacity: e.target.value})} 
        placeholder="Capacity" 
      />
      
      <select 
        name="chemistry" 
        value={batteryType.chemistry} 
        onChange={(e) => setBatteryType({...batteryType, chemistry: e.target.value})}
      >
        <option value="">Select Chemistry</option>
        <option value="Li-ion">Li-ion</option>
        <option value="NiMH">NiMH</option>
        <option value="Alkaline">Alkaline</option>
        {/* Add other chemistries as needed */}
      </select>

      <label>
        <input 
          type="checkbox" 
          name="button_top" 
          checked={batteryType.button_top} 
          onChange={(e) => setBatteryType({...batteryType, button_top: e.target.checked})}
        />
        Button Top
      </label>

      <label>
        <input 
          type="checkbox" 
          name="protected" 
          checked={batteryType.protected} 
          onChange={(e) => setBatteryType({...batteryType, protected: e.target.checked})}
        />
        Protected
      </label>

      <textarea 
        name="notes" 
        value={batteryType.notes} 
        onChange={(e) => setBatteryType({...batteryType, notes: e.target.value})} 
        placeholder="Notes" 
        rows="3" // Optional: adjust rows as needed
      />

      <button type="submit">Add Battery Type</button>

      {error && <p style={{color: 'red'}}>{error}</p>}
      {message && <p style={{color: 'green'}}>{message}</p>}
    </form>
  );
}

export default AddBatteryTypeForm;
