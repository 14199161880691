// src/pages/FlashlightsPage.js
import React, { useState, useEffect } from 'react';
import FlashlightTable from '../components/FlashlightTable';
import AddFlashlightForm from '../components/AddFlashlightForm';
import { fetchFlashlights } from '../services/flashlightService';
import { fetchEmitterTestsByFlashlightId } from '../services/emitterTestService';

function FlashlightsPage() {
    const [flashlights, setFlashlights] = useState([]);
    const [refreshTrigger, setRefreshTrigger] = useState(false);
    const [loading, setLoading] = useState(true);

    // Function to get unique emitters by type
    const uniqueEmittersByType = (emitters) => {
        const uniqueEmitterMap = {};
        emitters.forEach(emitter => {
            uniqueEmitterMap[emitter.type] = emitter; // Use the emitter type as the key to keep the first instance of the emitter type
        });
        return Object.values(uniqueEmitterMap);
    };

    const fetchFlashlightsWithEmitters = async () => {
        try {
            setLoading(true);
            const flashlightsData = await fetchFlashlights();
            const flashlightsWithEmitters = await Promise.all(
                flashlightsData.map(async (flashlight) => {
                    console.log(`Fetching emitters for flashlight ID: ${flashlight.id}`);
                    const emitters = await fetchEmitterTestsByFlashlightId(flashlight.id);
                    console.log(`Emitters for flashlight ID ${flashlight.id}:`, emitters);

                    // Filter unique emitters by type
                    const uniqueEmitters = uniqueEmittersByType(emitters);
                    
                    // Convert unique emitters to a string for display
                    const emittersString = uniqueEmitters.length > 0 
                        ? uniqueEmitters.map(emitter => emitter.type).join(', ') 
                        : 'N/A';
                    
                    console.log(`Formatted unique emitters for flashlight ID ${flashlight.id}:`, emittersString);

                    return {
                        ...flashlight,
                        emitters: emittersString,
                    };
                })
            );
            setFlashlights(flashlightsWithEmitters);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching flashlights with emitters:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFlashlightsWithEmitters();
    }, [refreshTrigger]);

    const handleFlashlightAdded = () => {
        setRefreshTrigger(prev => !prev);
    };

    const handleFlashlightRemoved = () => {
        setRefreshTrigger(prev => !prev);
    };

    const handleFlashlightUpdated = () => {
        setRefreshTrigger(prev => !prev); // Trigger refresh on update
    };

    if (loading) {
        return <div>Loading flashlights...</div>;
    }

    return (
        <div>
            <h1>Flashlights</h1>
            <AddFlashlightForm onFlashlightAdded={handleFlashlightAdded} />
            <FlashlightTable 
                flashlights={flashlights} 
                onFlashlightRemoved={handleFlashlightRemoved} 
                onFlashlightUpdated={handleFlashlightUpdated} // Pass the update handler
            />
        </div>
    );
}

export default FlashlightsPage;
