import React, { useState, useEffect } from 'react';
import { fetchEmitterTestResults, updateEmitterTestResult, deleteEmitterTestResult } from '../services/emitterTestService';

function EmitterTestResultsTable({ emitterTests, flashlights, emitters, refreshTrigger }) {
    const [displayedTests, setDisplayedTests] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [error, setError] = useState('');
    const [editId, setEditId] = useState(null);
    const [editData, setEditData] = useState({});
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    useEffect(() => {
        setDisplayedTests(emitterTests); // Populate displayedTests with emitterTests data
    }, [emitterTests, refreshTrigger]);

    // Format values with decimals
    const formatValue = (value, decimals) => {
        return value !== null && value !== undefined ? parseFloat(value).toFixed(decimals) : 'N/A';
    };

    const formatDateForInput = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // Function to get flashlight info by emitter_id, including nickname and headstamp if available
    const getFlashlightInfo = (emitter_id) => {
        const flashlight_id = Object.keys(emitters).find(key =>
            emitters[key].some(emitter => emitter.id === emitter_id)
        );
        if (!flashlight_id) return 'N/A';

        const flashlight = flashlights.find(flashlight => flashlight.id === parseInt(flashlight_id));
        if (!flashlight) return 'N/A';

        const { make, model, nickname, headstamp } = flashlight;
        const extraInfo = [nickname, headstamp].filter(Boolean).join(', ');

        return `${make} ${model} ${extraInfo ? `(${extraInfo})` : ''}`;
    };

    const getEmitterInfo = (emitter_id) => {
        const emitter = Object.values(emitters).flat().find(emitter => emitter.id === emitter_id);
        return emitter ? emitter.type : 'N/A';
    };

    const handleSort = (key) => {
        setSortConfig((prevConfig) => {
            let direction = 'ascending';
            if (prevConfig.key === key && prevConfig.direction === 'ascending') {
                direction = 'descending';
            }
            return { key, direction };
        });
    };

    const sortedAndFilteredResults = [...displayedTests]
        .filter((test) => {
            const searchLower = searchQuery.toLowerCase();
            // Search through various fields
            return (
                getFlashlightInfo(test.emitter_id).toLowerCase().includes(searchLower) ||
                getEmitterInfo(test.emitter_id).toLowerCase().includes(searchLower) ||
                test.notes?.toLowerCase().includes(searchLower) ||
                formatValue(test.measured_cct, 0).toLowerCase().includes(searchLower) ||
                formatValue(test.measured_ra, 1).toLowerCase().includes(searchLower) ||
                formatValue(test.measured_r9, 1).toLowerCase().includes(searchLower) ||
                formatValue(test.measured_duv, 4).toLowerCase().includes(searchLower) ||
                formatValue(test.measured_lux, 0).toLowerCase().includes(searchLower) ||
                formatValue(test.distance_tested, 1).toLowerCase().includes(searchLower) ||
                (test.test_date && formatDateForInput(test.test_date).includes(searchLower)) ||
                formatValue(test.flicker, 2).toLowerCase().includes(searchLower) // Add flicker to search
            );
        })
        .sort((a, b) => {
            if (!sortConfig.key) return 0;
            const valueA = sortConfig.key === 'flashlight_id' ? getFlashlightInfo(a.emitter_id) : a[sortConfig.key];
            const valueB = sortConfig.key === 'flashlight_id' ? getFlashlightInfo(b.emitter_id) : b[sortConfig.key];

            // Check if both values are numeric
            const isNumeric = !isNaN(parseFloat(valueA)) && isFinite(valueA) && !isNaN(parseFloat(valueB)) && isFinite(valueB);

            if (isNumeric) {
                return (parseFloat(valueA) - parseFloat(valueB)) * (sortConfig.direction === 'ascending' ? 1 : -1);
            } else {
                // Handle string comparison for non-numeric values
                if (valueA < valueB) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (valueA > valueB) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            }
        });

    const handleEditClick = (test) => {
        setEditId(test.id);
        setEditData({ ...test, test_date: formatDateForInput(test.test_date) });
    };

    const handleSaveClick = () => {
        const updatedData = {
            ...editData,
            test_date: editData.test_date ? editData.test_date : null
        };

        updateEmitterTestResult(editId, updatedData)
            .then(() => {
                alert('Emitter test result updated successfully!');
                setEditId(null);
                fetchEmitterTestResults().then(data => setDisplayedTests(data));
            })
            .catch(error => {
                console.error('Error updating emitter test result:', error);
                alert('Failed to update emitter test result.');
            });
    };

    const handleDeleteClick = (id) => {
        if (window.confirm('Are you sure you want to delete this emitter test result?')) {
            deleteEmitterTestResult(id)
                .then(() => {
                    alert('Emitter test result deleted successfully!');
                    fetchEmitterTestResults().then(data => setDisplayedTests(data));
                })
                .catch(error => {
                    console.error('Error deleting emitter test result:', error);
                    alert('Failed to delete emitter test result.');
                });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditData({ ...editData, [name]: value });
    };

    const getCCTTextColor = (cct) => {
        if (cct <= 2700) return '#8B0000';
        if (cct <= 3000) return '#FF4500';
        if (cct <= 4000) return '#FFFF00';
        if (cct <= 5000) return '#FFFACD';
        if (cct <= 6500) return '#ADD8E6';
        return '#FFFFFF';
    };

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    return (
        <div>
            <h2>Emitter Test Results</h2>
            <input
                type="text"
                placeholder="Search test results..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
            />
            <p>Showing {sortedAndFilteredResults.length} of {displayedTests.length} results</p>
            <table style={{ width: '100%', border: '1px solid black' }}>
                <thead>
                    <tr>
                        <th onClick={() => handleSort('flashlight_id')}>Flashlight</th>
                        <th onClick={() => handleSort('emitter_id')}>Emitter</th>
                        <th onClick={() => handleSort('measured_cct')}>Measured CCT</th>
                        <th onClick={() => handleSort('measured_ra')}>Measured Ra</th>
                        <th onClick={() => handleSort('measured_r9')}>Measured R9</th>
                        <th onClick={() => handleSort('measured_duv')}>Measured DUV</th>
                        <th onClick={() => handleSort('measured_lux')}>Measured Lux</th>
                        <th onClick={() => handleSort('distance_tested')}>Distance Tested</th>
                        <th onClick={() => handleSort('flicker')}>Flicker</th> {/* Flicker column added */}
                        <th onClick={() => handleSort('test_date')}>Test Date</th>
                        <th>Notes</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedAndFilteredResults.length > 0 ? (
                        sortedAndFilteredResults.map((result) => (
                            <tr key={result.id}>
                                {editId === result.id ? (
                                    <>
                                        <td>{getFlashlightInfo(result.emitter_id)}</td>
                                        <td>{getEmitterInfo(result.emitter_id)}</td>
                                        <td style={{ color: getCCTTextColor(result.measured_cct) }}>
                                            <input
                                                name="measured_cct"
                                                value={editData.measured_cct}
                                                onChange={handleInputChange}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                name="measured_ra"
                                                value={editData.measured_ra}
                                                onChange={handleInputChange}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                name="measured_r9"
                                                value={editData.measured_r9}
                                                onChange={handleInputChange}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                name="measured_duv"
                                                value={editData.measured_duv}
                                                onChange={handleInputChange}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                name="measured_lux"
                                                value={editData.measured_lux}
                                                onChange={handleInputChange}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                name="distance_tested"
                                                value={editData.distance_tested}
                                                onChange={handleInputChange}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                name="flicker"
                                                value={editData.flicker}
                                                onChange={handleInputChange}
                                            />
                                        </td> {/* Editable flicker field */}
                                        <td>
                                            <input
                                                type="date"
                                                name="test_date"
                                                value={editData.test_date}
                                                onChange={handleInputChange}
                                            />
                                        </td>
                                        <td>
                                            <textarea
                                                name="notes"
                                                value={editData.notes || ''}
                                                onChange={handleInputChange}
                                                rows="3"
                                                placeholder="Edit notes here..."
                                            />
                                        </td>
                                        <td>
                                            <button onClick={handleSaveClick} style={{ color: 'green' }}>Save</button>
                                            <button onClick={() => setEditId(null)} style={{ color: 'red', marginLeft: '10px' }}>Cancel</button>
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td>{getFlashlightInfo(result.emitter_id)}</td>
                                        <td>{getEmitterInfo(result.emitter_id)}</td>
                                        <td style={{ color: getCCTTextColor(result.measured_cct) }}>
                                            {formatValue(result.measured_cct, 0)}
                                        </td>
                                        <td>{formatValue(result.measured_ra, 1)}</td>
                                        <td>{formatValue(result.measured_r9, 1)}</td>
                                        <td>{formatValue(result.measured_duv, 4)}</td>
                                        <td>{formatValue(result.measured_lux, 0)}</td>
                                        <td>{formatValue(result.distance_tested, 1)}</td>
                                        <td>{formatValue(result.flicker, 2)}</td> {/* Display flicker */}
                                        <td>{result.test_date ? formatDateForInput(result.test_date) : 'N/A'}</td>
                                        <td>{result.notes || 'No notes'}</td>
                                        <td>
                                            <button onClick={() => handleEditClick(result)} style={{ color: 'blue' }}>Edit</button>
                                            <button onClick={() => handleDeleteClick(result.id)} style={{ color: 'red', marginLeft: '10px' }}>Delete</button>
                                        </td>
                                    </>
                                )}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="12">No test results available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default EmitterTestResultsTable;
