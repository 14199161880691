import React, { useEffect, useState } from 'react';
import FlashlightBatteryAssignmentTable from '../components/FlashlightBatteryAssignmentTable';
import AssignBatteryForm from '../components/AssignBatteryForm';
import OtherBatteryAssignmentTable from '../components/OtherBatteryAssignmentTable';
import { fetchFlashlights } from '../services/flashlightService';
import { fetchAssignments, fetchOtherAssignments } from '../services/assignmentService';

const AUTH_TOKEN = 'Your-Secret-Token';

function BatteryAssignmentPage() {
    const [assignments, setAssignments] = useState([]);
    const [flashlights, setFlashlights] = useState([]);
    const [otherAssignments, setOtherAssignments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [refreshTrigger, setRefreshTrigger] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    // Fetch flashlight assignments
    useEffect(() => {
        setLoading(true);
        fetchAssignments()
            .then(data => {
                setAssignments(data);
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching battery assignments:', err);
                setError('Failed to load battery assignments.');
                setLoading(false);
            });
    }, [refreshTrigger]);

    // Fetch "other" assignments
    useEffect(() => {
        fetchOtherAssignments()
            .then(data => {
                setOtherAssignments(data);
            })
            .catch(err => {
                console.error('Error fetching other assignments:', err);
                setError('Failed to load other assignments.');
            });
    }, [refreshTrigger]);

    // Fetch flashlights data
    useEffect(() => {
        setLoading(true);
        fetchFlashlights()
            .then(data => {
                setFlashlights(data);
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching flashlights:', err);
                setError('Failed to load flashlights.');
                setLoading(false);
            });
    }, []);

    const handleAssignmentChanged = () => {
        setRefreshTrigger(prev => !prev);
    };

    const handleOtherAssignmentRemoved = (assignmentId) => {
        fetch(`https://blackumbra.com/api/battery-assignments/other-assignments/${assignmentId}/remove`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${AUTH_TOKEN}`,
            },
        })
        .then(() => {
            handleAssignmentChanged();
        })
        .catch(error => console.error('Error removing other assignment:', error));
    };

    const handleOtherAssignmentUpdated = (assignmentId, updatedData) => {
        fetch(`https://blackumbra.com/api/battery-assignments/other-assignments/${assignmentId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AUTH_TOKEN}`,
            },
            body: JSON.stringify(updatedData),
        })
        .then(() => {
            handleAssignmentChanged();
        })
        .catch(error => console.error('Error updating other assignment:', error));
    };

    if (loading) {
        return <p>Loading data...</p>;
    }

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    return (
        <div>
            <h2>Battery Assignments</h2>

            {/* Unified Search Box */}
            <input
                type="text"
                placeholder="Search all assignments..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ margin: '10px 0', padding: '10px', width: '100%' }}
            />

            <AssignBatteryForm
                flashlights={flashlights}
                onAssignmentChanged={handleAssignmentChanged}
                refreshTrigger={refreshTrigger}
            />

            {/* Flashlight Assignments Table */}
            <FlashlightBatteryAssignmentTable
                assignments={assignments}
                flashlights={flashlights}
                batteries={[]}
                batteryTypes={[]}
                onAssignmentRemoved={handleAssignmentChanged}
                searchTerm={searchTerm}
            />

            {/* Other Assignments Table */}
            <OtherBatteryAssignmentTable
                otherAssignments={otherAssignments}
                onAssignmentRemoved={handleOtherAssignmentRemoved}
                onAssignmentUpdated={handleOtherAssignmentUpdated}
                searchTerm={searchTerm}
            />
        </div>
    );
}

export default BatteryAssignmentPage;
