import React, { useState } from 'react';
import axios from 'axios';

const PasswordProtect = ({ onAccessGranted }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://blackumbra.com/api/check-password', { password });
      if (response.data.success) {
        // Store the token in localStorage or state
        localStorage.setItem('authToken', response.data.token);
        onAccessGranted();
      } else {
        setError('Invalid password');
      }
    } catch (err) {
      setError('Error verifying password');
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <form onSubmit={handleSubmit} style={{ width: '300px', textAlign: 'center' }}>
        <h2>Please Enter Password</h2>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter password"
          required
          style={{ width: '100%', padding: '10px', margin: '10px 0' }}
        />
        <button type="submit" style={{ width: '100%', padding: '10px' }}>Submit</button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </form>
    </div>
  );
};

export default PasswordProtect;
