// src/services/batteryChargeSessionService.js
import axios from 'axios';

const API_URL = 'https://blackumbra.com/api/battery-charge-sessions';
const AUTH_TOKEN = 'Your-Secret-Token';

// Fetch all charge sessions
export const fetchAllChargeSessions = () => {
    return axios.get(`${API_URL}/charge-sessions`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${AUTH_TOKEN}`
        }
    })
    .then(response => response.data)
    .catch(error => {
        console.error('Error fetching all charge sessions:', error);
        throw error;
    });
};

// Fetch charge sessions for a specific battery
export const fetchChargeSessions = (batteryId) => {
    if (!batteryId) {
      console.error('fetchChargeSessions: batteryId is undefined');
      return Promise.reject('Battery ID is required.');
    }

    return axios.get(`${API_URL}/${batteryId}/charge-sessions`, {
      headers: {
        'Authorization': `Bearer ${AUTH_TOKEN}`,
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      return Array.isArray(response.data) ? response.data : []; // Ensure the response is an array
    })
    .catch(error => {
      console.error('Error fetching charge sessions:', error);
      throw error;
    });
};


// Add a new charge session for a specific battery
export const addChargeSession = (batteryId, chargeSession) => {
    return axios.post(`${API_URL}/${batteryId}/charge-sessions`, chargeSession, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${AUTH_TOKEN}`
        }
    })
    .then(response => response.data)
    .catch(error => {
        console.error('Error adding charge session:', error);
        throw error;
    });
};

// Update an existing charge session
export const updateChargeSession = (sessionId, chargeSession) => {
    return axios.put(`${API_URL}/charge-sessions/${sessionId}`, chargeSession, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${AUTH_TOKEN}`
        }
    })
    .then(response => response.data)
    .catch(error => {
        console.error('Error updating charge session:', error);
        throw error;
    });
};

// Delete a charge session
export const deleteChargeSession = (sessionId) => {
    return axios.delete(`${API_URL}/charge-sessions/${sessionId}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${AUTH_TOKEN}`
        }
    })
    .then(response => response.data)
    .catch(error => {
        console.error('Error deleting charge session:', error);
        throw error;
    });
};