import React, { useState, useEffect } from 'react';
import { fetchAllChargeSessions, addChargeSession, updateChargeSession, deleteChargeSession } from '../services/batteryChargeSessionService';

function ChargeSessionsTable({ batteries = [], batteryTypes = [], refreshTrigger }) {
    const [chargeSessions, setChargeSessions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [newSession, setNewSession] = useState({
        battery_id: '',
        charge_date: '',
        charge_amount: '',
        discharge_amount: '',
        internal_resistance: '',
        voltage: '',
        is_refresh_cycle: false // Initialize as boolean false
    });
    const [editSessionId, setEditSessionId] = useState(null);
    const [editData, setEditData] = useState({});
    const [sortConfig, setSortConfig] = useState({ key: 'charge_date', direction: 'ascending' });

    // Function to fetch all charge sessions
    const refreshChargeSessions = () => {
        setLoading(true);
        fetchAllChargeSessions()
            .then(data => {
                setChargeSessions(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching charge sessions:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        refreshChargeSessions();
    }, [refreshTrigger]);

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const updatedValue = type === 'checkbox' ? checked : value;
        setNewSession({ ...newSession, [name]: updatedValue });
    };

    // Add a new charge session
    const handleSubmit = (e) => {
        e.preventDefault();
        const batteryId = parseInt(newSession.battery_id, 10);

        if (isNaN(batteryId)) {
            alert('Please select a valid battery.');
            return;
        }

        const localDate = new Date(newSession.charge_date + 'T00:00:00Z');
        const formattedDate = localDate.toISOString().split('T')[0];

        const payload = {
            charge_date: formattedDate,
            charge_amount: parseInt(newSession.charge_amount, 10),
            discharge_amount: newSession.discharge_amount ? parseInt(newSession.discharge_amount, 10) : null,
            internal_resistance: newSession.internal_resistance ? parseFloat(newSession.internal_resistance) : null,
            voltage: newSession.voltage ? parseFloat(newSession.voltage) : null,
            is_refresh_cycle: newSession.is_refresh_cycle ? 1 : 0 // Convert boolean to 1 or 0
        };

        addChargeSession(batteryId, payload)
            .then(() => {
                refreshChargeSessions();
                setNewSession({
                    battery_id: '',
                    charge_date: '',
                    charge_amount: '',
                    discharge_amount: '',
                    internal_resistance: '',
                    voltage: '',
                    is_refresh_cycle: false // Reset to false after submission
                });
            })
            .catch(error => {
                console.error('Error adding charge session:', error);
                alert('Failed to add charge session. Please try again.');
            });
    };

    // Handle click on edit button
    const handleEditClick = (session) => {
        setEditSessionId(session.id);
        setEditData({
            ...session,
            is_refresh_cycle: session.is_refresh_cycle === 1 // Convert to boolean for checkbox
        });
    };

    // Handle edit form input changes
    const handleEditChange = (e) => {
        const { name, value, type, checked } = e.target;
        const updatedValue = type === 'checkbox' ? checked : value;
        setEditData({ ...editData, [name]: updatedValue });
    };

    // Save edited charge session
    const handleEditSave = () => {
        const formattedEditData = {
            ...editData,
            charge_date: new Date(editData.charge_date).toISOString().split('T')[0],
            is_refresh_cycle: editData.is_refresh_cycle ? 1 : 0 // Convert boolean to 1 or 0 for saving
        };

        updateChargeSession(editSessionId, formattedEditData)
            .then(() => {
                refreshChargeSessions();
                setEditSessionId(null);
                setEditData({});
            })
            .catch(error => console.error('Error updating charge session:', error));
    };

    // Cancel edit mode
    const handleEditCancel = () => {
        setEditSessionId(null);
        setEditData({});
    };

    // Delete a charge session
    const handleDeleteClick = (sessionId) => {
        if (window.confirm('Are you sure you want to delete this charge session?')) {
            deleteChargeSession(sessionId)
                .then(() => {
                    refreshChargeSessions();
                })
                .catch(error => console.error('Error deleting charge session:', error));
        }
    };

    // Handle sorting functionality
    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    // Function to get the sort indicator (▲ for ascending, ▼ for descending)
    const getSortIndicator = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '▲' : '▼';
        }
        return '';
    };

    // Enrich charge sessions with battery and battery type information
    const enrichedChargeSessions = chargeSessions.map(session => {
        const battery = batteries.find(b => b.id === session.battery_id);
        const batteryType = battery ? batteryTypes.find(bt => bt.id === battery.type_id) : null;

        return {
            ...session,
            battery_brand: battery ? battery.brand : 'Unknown',
            battery_model: battery ? battery.model : 'Unknown',
            battery_capacity: batteryType ? batteryType.stated_capacity : 'Unknown',
            battery_chemistry: batteryType ? batteryType.chemistry : 'Unknown'
        };
    });

    // Filter charge sessions based on the search term
    const filteredChargeSessions = enrichedChargeSessions.filter(session => {
        return Object.values(session).some(value =>
            value ? value.toString().toLowerCase().includes(searchTerm.toLowerCase()) : false
        );
    });

    // Sort charge sessions
    const sortedChargeSessions = [...filteredChargeSessions].sort((a, b) => {
        if (a[sortConfig.key] === null) return 1;
        if (b[sortConfig.key] === null) return -1;
        if (a[sortConfig.key] === b[sortConfig.key]) return 0;
        return (a[sortConfig.key] > b[sortConfig.key] ? 1 : -1) * (sortConfig.direction === 'ascending' ? 1 : -1);
    });

    if (loading) {
        return <p>Loading charge sessions...</p>;
    }

    return (
        <div>
            <h3>Add Charge Session</h3>
            <form onSubmit={handleSubmit}>
                <select
                    name="battery_id"
                    value={newSession.battery_id}
                    onChange={handleChange}
                    required
                >
                    <option value="">Select Battery</option>
                    {batteries.map(battery => (
                        <option key={battery.id} value={battery.id.toString()}>
                            {battery.barcode} - {battery.brand} {battery.model} - {battery.notes || 'No Notes'}
                        </option>
                    ))}
                </select>
                <input name="charge_date" type="date" value={newSession.charge_date} onChange={handleChange} required />
                <input name="charge_amount" type="number" value={newSession.charge_amount} onChange={handleChange} placeholder="Charge Amount" required />
                <input name="discharge_amount" type="number" value={newSession.discharge_amount} onChange={handleChange} placeholder="Discharge Amount" />
                <input name="internal_resistance" type="number" value={newSession.internal_resistance} onChange={handleChange} placeholder="Internal Resistance" />
                <input name="voltage" type="number" value={newSession.voltage} onChange={handleChange} placeholder="Voltage" />
                <label>
                    Refresh Cycle:
                    <input
                        type="checkbox"
                        name="is_refresh_cycle"
                        checked={newSession.is_refresh_cycle}
                        onChange={handleChange}
                    />
                </label>
                <button type="submit">Add Session</button>
            </form>

            <h3>Search Charge Sessions</h3>
            <div>
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{
                        width: '100%',
                        padding: '10px',
                        marginBottom: '20px',
                        border: '1px solid #ccc',
                        boxSizing: 'border-box'
                    }}
                />
            </div>

            <h2>All Charge Sessions</h2>
            {/* Display count of filtered results */}
            <p>Showing {filteredChargeSessions.length} of {chargeSessions.length} charge sessions</p>
            <table style={{ width: '100%', border: '1px solid black' }}>
                <thead>
                    <tr>
                        <th onClick={() => handleSort('barcode')}>Barcode {getSortIndicator('barcode')}</th>
                        <th onClick={() => handleSort('battery_id')}>Battery ID {getSortIndicator('battery_id')}</th>
                        <th onClick={() => handleSort('battery_brand')}>Brand {getSortIndicator('battery_brand')}</th>
                        <th onClick={() => handleSort('battery_model')}>Model {getSortIndicator('battery_model')}</th>
                        <th onClick={() => handleSort('battery_capacity')}>Capacity {getSortIndicator('battery_capacity')}</th>
                        <th onClick={() => handleSort('battery_chemistry')}>Chemistry {getSortIndicator('battery_chemistry')}</th>
                        <th onClick={() => handleSort('charge_date')}>Charge Date {getSortIndicator('charge_date')}</th>
                        <th onClick={() => handleSort('charge_amount')}>Charge Amount {getSortIndicator('charge_amount')}</th>
                        <th onClick={() => handleSort('discharge_amount')}>Discharge Amount {getSortIndicator('discharge_amount')}</th>
                        <th onClick={() => handleSort('internal_resistance')}>Internal Resistance {getSortIndicator('internal_resistance')}</th>
                        <th onClick={() => handleSort('voltage')}>Voltage {getSortIndicator('voltage')}</th>
                        <th onClick={() => handleSort('is_refresh_cycle')}>Refresh Cycle {getSortIndicator('is_refresh_cycle')}</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedChargeSessions.map((session) => {
                        return (
                            <tr key={session.id}>
                                {editSessionId === session.id ? (
                                    <>
                                        <td>{session.barcode}</td>
                                        <td>{session.battery_id}</td>
                                        <td>{session.battery_brand}</td>
                                        <td>{session.battery_model}</td>
                                        <td>{session.battery_capacity}</td>
                                        <td>{session.battery_chemistry}</td>
                                        <td>
                                            <input
                                                name="charge_date"
                                                type="date"
                                                value={editData.charge_date}
                                                onChange={handleEditChange}
                                            />
                                        </td>
                                        <td><input name="charge_amount" type="number" value={editData.charge_amount} onChange={handleEditChange} /></td>
                                        <td><input name="discharge_amount" type="number" value={editData.discharge_amount} onChange={handleEditChange} /></td>
                                        <td><input name="internal_resistance" type="number" value={editData.internal_resistance} onChange={handleEditChange} /></td>
                                        <td><input name="voltage" type="number" value={editData.voltage} onChange={handleEditChange} /></td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                name="is_refresh_cycle"
                                                checked={editData.is_refresh_cycle}
                                                onChange={handleEditChange}
                                            />
                                        </td>
                                        <td>
                                            <button onClick={handleEditSave} style={{ color: 'green' }}>Save</button>
                                            <button onClick={handleEditCancel} style={{ marginLeft: '10px', color: 'red' }}>Cancel</button>
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td>{session.barcode}</td>
                                        <td>{session.battery_id}</td>
                                        <td>{session.battery_brand}</td>
                                        <td>{session.battery_model}</td>
                                        <td>{session.battery_capacity}</td>
                                        <td>{session.battery_chemistry}</td>
                                        <td>{new Date(session.charge_date).toLocaleDateString('en-US', { timeZone: 'UTC' })}</td>
                                        <td>{session.charge_amount}</td>
                                        <td>{session.discharge_amount || 'N/A'}</td>
                                        <td>{session.internal_resistance ? Math.round(session.internal_resistance) : 'N/A'}</td>
                                        <td>{session.voltage || 'N/A'}</td>
                                        <td>{session.is_refresh_cycle ? 'Yes' : 'No'}</td>
                                        <td>
                                            <button onClick={() => handleEditClick(session)}>Edit</button>
                                            <button onClick={() => handleDeleteClick(session.id)} style={{ marginLeft: '10px', color: 'red' }}>Delete</button>
                                        </td>
                                    </>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default ChargeSessionsTable;
