import React, { useState, useEffect } from 'react';
import { fetchBatteries, fetchBatteryTypes } from '../services/batteryService';

function OtherBatteryAssignmentTable({ otherAssignments, onAssignmentRemoved }) {
    const [sortConfig, setSortConfig] = useState({ key: 'object_name', direction: 'ascending' });
    const [searchTerm, setSearchTerm] = useState('');
    const [batteries, setBatteries] = useState([]);
    const [batteryTypes, setBatteryTypes] = useState([]);

    // Fetch batteries and battery types when the component mounts
    useEffect(() => {
        fetchBatteries()
            .then(fetchedBatteries => {
                setBatteries(fetchedBatteries);
            })
            .catch(error => console.error('Error fetching batteries:', error));

        fetchBatteryTypes()
            .then(fetchedBatteryTypes => {
                setBatteryTypes(fetchedBatteryTypes);
            })
            .catch(error => console.error('Error fetching battery types:', error));
    }, []);

    // Sorting logic
    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    // Get battery info: Find battery by barcode and then match its type
    const getBatteryInfo = (batteryBarcode) => {
        const battery = batteries.find(b => b.barcode === batteryBarcode);
        if (!battery) return 'Unknown Battery';

        // Find the battery type using the battery's type_id
        const batteryType = batteryTypes.find(type => type.id === battery.type_id);
        if (!batteryType) return 'Unknown Type';

        return `${batteryType.brand || ''} ${batteryType.model || ''} ${batteryType.stated_capacity ? batteryType.stated_capacity + 'mAh' : ''}`;
    };

    // Filter assignments based on search input
    const filteredAssignments = otherAssignments.filter((assignment) => {
        const batteryInfo = getBatteryInfo(assignment.battery_barcode).toLowerCase();
        const objectName = assignment.object_name ? assignment.object_name.toLowerCase() : 'unknown object';
        const notes = assignment.notes ? assignment.notes.toLowerCase() : '';
        const barcode = assignment.battery_barcode ? assignment.battery_barcode.toLowerCase() : '';
        
        return (
            batteryInfo.includes(searchTerm.toLowerCase()) ||
            objectName.includes(searchTerm.toLowerCase()) ||
            notes.includes(searchTerm.toLowerCase()) ||
            barcode.includes(searchTerm.toLowerCase())
        );
    });

    return (
        <div>
            <h2>Other Battery Assignments</h2>
            <p>Showing {filteredAssignments.length} of {otherAssignments.length} results</p>

            <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ marginBottom: '10px' }}
            />

            <table style={{ width: '100%', border: '1px solid black' }}>
                <thead>
                    <tr>
                        <th onClick={() => handleSort('object_name')}>
                            Object {sortConfig.key === 'object_name' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                        </th>
                        <th onClick={() => handleSort('battery_brand')}>
                            Battery {sortConfig.key === 'battery_brand' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                        </th>
                        <th onClick={() => handleSort('battery_barcode')}>
                            Barcode {sortConfig.key === 'battery_barcode' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                        </th>
                        <th onClick={() => handleSort('notes')}>
                            Notes {sortConfig.key === 'notes' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                        </th>
                        <th>Actions</th> {/* No sorting for actions */}
                    </tr>
                </thead>
                <tbody>
                    {filteredAssignments.length > 0 ? (
                        filteredAssignments.map((assignment) => (
                            <tr key={assignment.id}>
                                <td>{assignment.object_name || 'Unknown Object'}</td>
                                <td>{getBatteryInfo(assignment.battery_barcode)}</td>
                                <td>{assignment.battery_barcode || 'N/A'}</td>
                                <td>{assignment.notes || 'No notes available'}</td>
                                <td>
                                    <button onClick={() => onAssignmentRemoved(assignment.id)}>Remove Assignment</button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5">No assignments available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default OtherBatteryAssignmentTable;
