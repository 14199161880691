import React, { useState, useEffect } from 'react';
import { fetchChargeSessions, addChargeSession, updateChargeSession, deleteChargeSession } from '../services/batteryChargeSessionService';
import { fetchBatteryDetails } from '../services/batteryService'; // Import the new fetchBatteryDetails function

// Function to format date to MM-DD-YYYY and handle timezone
const formatDateForDisplay = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    
    // Get timezone offset in milliseconds
    const timezoneOffset = date.getTimezoneOffset() * 60 * 1000;

    // Adjust date for timezone
    const adjustedDate = new Date(date.getTime() + timezoneOffset);

    const year = adjustedDate.getUTCFullYear();
    const month = String(adjustedDate.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(adjustedDate.getUTCDate()).padStart(2, '0');
    return `${month}-${day}-${year}`;
};

function ChargeSessionTable({ batteryId, onBackClick }) {
    const [chargeSessions, setChargeSessions] = useState([]);
    const [batteryDetails, setBatteryDetails] = useState(null); // State to hold battery details
    const [loading, setLoading] = useState(true);
    const [newSession, setNewSession] = useState({
        charge_date: '',
        charge_amount: '',
        discharge_amount: '',
        internal_resistance: '',
        voltage: '',
        is_refresh_cycle: false
    });
    const [editSessionId, setEditSessionId] = useState(null);
    const [editData, setEditData] = useState({});
    const [sortConfig, setSortConfig] = useState({ key: 'charge_date', direction: 'ascending' });
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // Fetch charge sessions
        setLoading(true);
        fetchChargeSessions(batteryId)
            .then(data => {
                setChargeSessions(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching charge sessions:', error);
                setLoading(false);
            });

        // Fetch battery details for the header
        fetchBatteryDetails(batteryId)
            .then(details => {
                setBatteryDetails(details); // Set the battery details for header
            })
            .catch(error => {
                console.error('Error fetching battery details:', error);
            });
    }, [batteryId]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const updatedValue = type === 'checkbox' ? checked : value;
        setNewSession({ ...newSession, [name]: updatedValue });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formattedNewSession = {
            ...newSession,
            charge_date: new Date(newSession.charge_date).toISOString().split('T')[0],
            internal_resistance: newSession.internal_resistance || null,
            voltage: newSession.voltage || null,
            is_refresh_cycle: newSession.is_refresh_cycle
        };
        addChargeSession(batteryId, formattedNewSession)
            .then(() => {
                fetchChargeSessions(batteryId)
                    .then(data => setChargeSessions(data))
                    .catch(error => console.error('Error fetching charge sessions:', error));
                setNewSession({
                    charge_date: '',
                    charge_amount: '',
                    discharge_amount: '',
                    internal_resistance: '',
                    voltage: '',
                    is_refresh_cycle: false
                });
            })
            .catch(error => console.error('Error adding charge session:', error));
    };

    const handleEditClick = (session) => {
        setEditSessionId(session.id);
        setEditData({
            ...session,
            charge_date: new Date(session.charge_date).toISOString().split('T')[0],
            is_refresh_cycle: session.is_refresh_cycle === 1
        });
    };

    const handleEditChange = (e) => {
        const { name, value, type, checked } = e.target;
        const updatedValue = type === 'checkbox' ? checked : value;
        setEditData({ ...editData, [name]: updatedValue });
    };

    const handleEditSave = () => {
        const formattedEditData = {
            ...editData,
            charge_date: new Date(editData.charge_date).toISOString().split('T')[0],
            internal_resistance: editData.internal_resistance || null,
            voltage: editData.voltage || null,
            is_refresh_cycle: editData.is_refresh_cycle
        };

        updateChargeSession(editSessionId, formattedEditData)
            .then(() => {
                fetchChargeSessions(batteryId)
                    .then(data => setChargeSessions(data))
                    .catch(error => console.error('Error fetching charge sessions:', error));
                setEditSessionId(null);
                setEditData({});
            })
            .catch(error => console.error('Error updating charge session:', error));
    };

    const handleEditCancel = () => {
        setEditSessionId(null);
        setEditData({});
    };

    const handleDeleteClick = (sessionId) => {
        if (window.confirm('Are you sure you want to delete this charge session?')) {
            deleteChargeSession(sessionId)
                .then(() => {
                    fetchChargeSessions(batteryId)
                        .then(data => setChargeSessions(data))
                        .catch(error => console.error('Error fetching charge sessions:', error));
                })
                .catch(error => console.error('Error deleting charge session:', error));
        }
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getSortIndicator = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '▲' : '▼';
        }
        return '';
    };

    const sortedChargeSessions = [...chargeSessions].sort((a, b) => {
        if (a[sortConfig.key] === null) return 1;
        if (b[sortConfig.key] === null) return -1;
        if (a[sortConfig.key] === b[sortConfig.key]) return 0;
        return (a[sortConfig.key] > b[sortConfig.key] ? 1 : -1) * (sortConfig.direction === 'ascending' ? 1 : -1);
    });

    const filteredChargeSessions = sortedChargeSessions.filter(session => {
        return Object.values(session).some(value =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    if (loading) {
        return <p>Loading charge sessions...</p>;
    }

    return (
        <div>
            {/* Page header with battery make, model, and barcode */}
            <h2>Charge Sessions for: {batteryDetails ? `${batteryDetails.brand} ${batteryDetails.model} (Barcode: ${batteryDetails.barcode})` : 'Loading...'}</h2>
            <p>Showing {filteredChargeSessions.length} of {chargeSessions.length} charge sessions</p>
            <button onClick={onBackClick}>Back</button>
            <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
            />
            <table style={{ width: '100%', border: '1px solid black' }}>
                <thead>
                    <tr>
                        <th onClick={() => handleSort('charge_date')}>Charge Date {getSortIndicator('charge_date')}</th>
                        <th onClick={() => handleSort('charge_amount')}>Charge Amount {getSortIndicator('charge_amount')}</th>
                        <th onClick={() => handleSort('discharge_amount')}>Discharge Amount {getSortIndicator('discharge_amount')}</th>
                        <th onClick={() => handleSort('internal_resistance')}>Internal Resistance {getSortIndicator('internal_resistance')}</th>
                        <th onClick={() => handleSort('voltage')}>Voltage {getSortIndicator('voltage')}</th>
                        <th onClick={() => handleSort('is_refresh_cycle')}>Refresh Cycle {getSortIndicator('is_refresh_cycle')}</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredChargeSessions.map((session) => (
                        <tr key={session.id}>
                            {editSessionId === session.id ? (
                                <>
                                    <td>
                                        <input
                                            name="charge_date"
                                            type="date"
                                            value={editData.charge_date}
                                            onChange={handleEditChange}
                                        />
                                    </td>
                                    <td><input name="charge_amount" type="number" value={editData.charge_amount} onChange={handleEditChange} /></td>
                                    <td><input name="discharge_amount" type="number" value={editData.discharge_amount || ''} onChange={handleEditChange} /></td>
                                    <td><input name="internal_resistance" type="number" value={editData.internal_resistance || ''} onChange={handleEditChange} /></td>
                                    <td><input name="voltage" type="number" value={editData.voltage || ''} onChange={handleEditChange} /></td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            name="is_refresh_cycle"
                                            checked={editData.is_refresh_cycle}
                                            onChange={handleEditChange}
                                        />
                                    </td>
                                    <td>
                                        <button onClick={handleEditSave} style={{ color: 'green' }}>Save</button>
                                        <button onClick={handleEditCancel} style={{ marginLeft: '10px', color: 'red' }}>Cancel</button>
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td>{formatDateForDisplay(session.charge_date)}</td>
                                    <td>{session.charge_amount}</td>
                                    <td>{session.discharge_amount || 'N/A'}</td>
                                    <td>{session.internal_resistance ? Math.round(session.internal_resistance) : 'N/A'}</td>
                                    <td>{session.voltage || 'N/A'}</td>
                                    <td>{session.is_refresh_cycle ? 'Yes' : 'No'}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(session)}>Edit</button>
                                        <button onClick={() => handleDeleteClick(session.id)} style={{ marginLeft: '10px', color: 'red' }}>Delete</button>
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>

            <h3>Add Charge Session</h3>
            <form onSubmit={handleSubmit}>
                <input name="charge_date" type="date" value={newSession.charge_date} onChange={handleChange} required />
                <input name="charge_amount" type="number" value={newSession.charge_amount} onChange={handleChange} placeholder="Charge Amount" required />
                <input name="discharge_amount" type="number" value={newSession.discharge_amount} onChange={handleChange} placeholder="Discharge Amount" />
                <input name="internal_resistance" type="number" value={newSession.internal_resistance || ''} onChange={handleChange} placeholder="Internal Resistance" />
                <input name="voltage" type="number" value={newSession.voltage || ''} onChange={handleChange} placeholder="Voltage" />
                <label>
                    Refresh Cycle:
                    <input
                        type="checkbox"
                        name="is_refresh_cycle"
                        checked={newSession.is_refresh_cycle}
                        onChange={(e) => handleChange(e)}
                    />
                </label>
                <button type="submit">Add Session</button>
            </form>
        </div>
    );
}

export default ChargeSessionTable;