import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AUTH_TOKEN = 'Your-Secret-Token';

function BatteryTypesTable({ batteryTypes: initialBatteryTypes, refreshTrigger }) {
    const [localBatteryTypes, setLocalBatteryTypes] = useState(initialBatteryTypes);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [editedRow, setEditedRow] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: 'brand', direction: 'ascending' });
    const [searchTerm, setSearchTerm] = useState('');

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${AUTH_TOKEN}`
    };

    const fetchBatteryTypes = () => {
        setLoading(true);
        axios.get('https://blackumbra.com/api/battery-types', { headers })
            .then(response => {
                // Assuming response includes 'num_batteries' for each battery type
                setLocalBatteryTypes(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching battery types:', error);
                setError('Failed to load battery types.');
                setLoading(false);
            });
    };

    useEffect(() => {
        console.log('Fetching Battery Types in BatteryTypesTable...');
        fetchBatteryTypes();
    }, [refreshTrigger]);

    useEffect(() => {
        console.log('BatteryTypesTable received new batteryTypes prop:', initialBatteryTypes);
        setLocalBatteryTypes(initialBatteryTypes);
    }, [initialBatteryTypes]);


    const handleInputChange = (e, id) => {
        const { name, value } = e.target;
        setLocalBatteryTypes(prevBatteryTypes =>
            prevBatteryTypes.map(bt =>
                bt.id === id ? { ...bt, [name]: value } : bt
            )
        );
    };

    const handleSave = (id) => {
        const updatedBatteryType = localBatteryTypes.find(bt => bt.id === id);

        // Ensure that CDR is saved as a float with one decimal
        if (updatedBatteryType.cdr) {
            updatedBatteryType.cdr = parseFloat(updatedBatteryType.cdr).toFixed(1);
        }

        axios.put(`https://blackumbra.com/api/battery-types/${id}`, updatedBatteryType, { headers })
            .then(response => {
                setEditedRow(null);
                alert('Changes saved successfully!');
            })
            .catch(error => {
                console.error('Error saving changes:', error);
                alert('Failed to save changes.');
            });
    };


    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this battery type? This action cannot be undone.')) {
            axios.delete(`https://blackumbra.com/api/battery-types/${id}`, { headers })
                .then(response => {
                    setLocalBatteryTypes(prevBatteryTypes => prevBatteryTypes.filter(bt => bt.id !== id));
                    alert('Battery type deleted successfully!');
                })
                .catch(error => {
                    console.error('Error deleting battery type:', error);
                    alert('Failed to delete battery type.');
                });
        }
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getSortIndicator = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '▲' : '▼';
        }
        return '';
    };

    const sortedBatteryTypes = [...localBatteryTypes].sort((a, b) => {
        if (a[sortConfig.key] === null) return 1;
        if (b[sortConfig.key] === null) return -1;
        if (a[sortConfig.key] === b[sortConfig.key]) return 0;
        return (a[sortConfig.key] > b[sortConfig.key] ? 1 : -1) * (sortConfig.direction === 'ascending' ? 1 : -1);
    });

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredBatteryTypes = sortedBatteryTypes.filter(batteryType => {
        return Object.values(batteryType).some(value =>
            value ? value.toString().toLowerCase().includes(searchTerm.toLowerCase()) : false
        );
    });

    if (loading) {
        return <p>Loading battery types...</p>;
    }

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    return (
        <div>
            <h2>Battery Types</h2>
            <p>Showing {filteredBatteryTypes.length} of {localBatteryTypes.length} results</p>
            <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearch}
                style={{ marginBottom: '10px' }}
            />
            <table style={{ width: '100%', border: '1px solid black' }}>
                <thead>
                    <tr>
                        <th onClick={() => requestSort('brand')}>
                            Brand {getSortIndicator('brand')}
                        </th>
                        <th onClick={() => requestSort('model')}>
                            Model {getSortIndicator('model')}
                        </th>
                        <th onClick={() => requestSort('type')}>
                            Type {getSortIndicator('type')}
                        </th>
                        <th onClick={() => requestSort('nominal_voltage')}>
                            Nominal Voltage {getSortIndicator('nominal_voltage')}
                        </th>
                        <th onClick={() => requestSort('cdr')}>
                            CDR {getSortIndicator('cdr')}
                        </th>
                        <th onClick={() => requestSort('stated_capacity')}>
                            Capacity {getSortIndicator('stated_capacity')}
                        </th>
                        <th onClick={() => requestSort('chemistry')}>
                            Chemistry {getSortIndicator('chemistry')}
                        </th>
                        <th onClick={() => requestSort('button_top')}>
                            Button Top {getSortIndicator('button_top')}
                        </th>
                        <th onClick={() => requestSort('protected')}>
                            Protected {getSortIndicator('protected')}
                        </th>
                        <th onClick={() => requestSort('notes')}>
                            Notes {getSortIndicator('notes')}
                        </th>
                        <th onClick={() => requestSort('num_batteries')}>
                            # of Batteries {getSortIndicator('num_batteries')}
                        </th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredBatteryTypes.map((batteryType) => (
                        <tr key={batteryType.id}>
                            <td>
                                {editedRow === batteryType.id ? (
                                    <input
                                        type="text"
                                        name="brand"
                                        value={batteryType.brand}
                                        onChange={(e) => handleInputChange(e, batteryType.id)}
                                    />
                                ) : (
                                    batteryType.brand
                                )}
                            </td>
                            <td>
                                {editedRow === batteryType.id ? (
                                    <input
                                        type="text"
                                        name="model"
                                        value={batteryType.model}
                                        onChange={(e) => handleInputChange(e, batteryType.id)}
                                    />
                                ) : (
                                    batteryType.model
                                )}
                            </td>
                            <td>
                                {editedRow === batteryType.id ? (
                                    <input
                                        type="text"
                                        name="type"
                                        value={batteryType.type}
                                        onChange={(e) => handleInputChange(e, batteryType.id)}
                                    />
                                ) : (
                                    batteryType.type
                                )}
                            </td>
                            <td>
                                {editedRow === batteryType.id ? (
                                    <input
                                        type="number"
                                        name="nominal_voltage"
                                        value={batteryType.nominal_voltage || ''}
                                        onChange={(e) => handleInputChange(e, batteryType.id)}
                                    />
                                ) : (
                                    batteryType.nominal_voltage || 'N/A'
                                )}
                            </td>
                            <td>
                                {editedRow === batteryType.id ? (
                                    <input
                                        type="number"
                                        name="cdr"
                                        value={batteryType.cdr || ''}
                                        onChange={(e) => handleInputChange(e, batteryType.id)}
                                    />
                                ) : (
                                    batteryType.cdr
                                        ? parseFloat(batteryType.cdr).toFixed(1)  // Display with 1 decimal
                                        : 'N/A'
                                )}
                            </td>
                            <td>
                                {editedRow === batteryType.id ? (
                                    <input
                                        type="number"
                                        name="stated_capacity"
                                        value={batteryType.stated_capacity || ''}
                                        onChange={(e) => handleInputChange(e, batteryType.id)}
                                    />
                                ) : (
                                    batteryType.stated_capacity || 'N/A'
                                )}
                            </td>
                            <td>
                                {editedRow === batteryType.id ? (
                                    <input
                                        type="text"
                                        name="chemistry"
                                        value={batteryType.chemistry || ''}
                                        onChange={(e) => handleInputChange(e, batteryType.id)}
                                    />
                                ) : (
                                    batteryType.chemistry || 'N/A'
                                )}
                            </td>
                            <td>
                                {editedRow === batteryType.id ? (
                                    <select
                                        name="button_top"
                                        value={batteryType.button_top ? 'Yes' : 'No'}
                                        onChange={(e) =>
                                            handleInputChange(
                                                { target: { name: 'button_top', value: e.target.value === 'Yes' } },
                                                batteryType.id
                                            )
                                        }
                                    >
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                ) : (
                                    batteryType.button_top ? 'Yes' : 'No'
                                )}
                            </td>
                            <td>
                                {editedRow === batteryType.id ? (
                                    <select
                                        name="protected"
                                        value={batteryType.protected ? 'Yes' : 'No'}
                                        onChange={(e) =>
                                            handleInputChange(
                                                { target: { name: 'protected', value: e.target.value === 'Yes' } },
                                                batteryType.id
                                            )
                                        }
                                    >
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                ) : (
                                    batteryType.protected ? 'Yes' : 'No'
                                )}
                            </td>
                            <td>
                                {editedRow === batteryType.id ? (
                                    <input
                                        type="text"
                                        name="notes"
                                        value={batteryType.notes || ''}
                                        onChange={(e) => handleInputChange(e, batteryType.id)}
                                    />
                                ) : (
                                    batteryType.notes || 'N/A'
                                )}
                            </td>
                            <td>
                                {batteryType.num_batteries || 0} {/* Display the count of batteries */}
                            </td>
                            <td>
                                {editedRow === batteryType.id ? (
                                    <>
                                        <button onClick={() => handleSave(batteryType.id)}>Save</button>
                                        <button onClick={() => setEditedRow(null)} style={{ marginLeft: '10px' }}>
                                            Cancel
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button onClick={() => setEditedRow(batteryType.id)}>Edit</button>
                                        <button onClick={() => handleDelete(batteryType.id)} style={{ marginLeft: '10px', color: 'red' }}>
                                            Delete
                                        </button>
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default BatteryTypesTable;
