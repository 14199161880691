import React, { useState, useEffect } from 'react';
import { addFlashlight } from '../services/flashlightService';
import { fetchBatteries } from '../services/batteryService';

function AddFlashlightForm({ onFlashlightAdded }) {
    const [flashlight, setFlashlight] = useState({
        make: '',
        model: '',
        battery_size: '',
        battery_chemistry: '',
        max_slots: 1, // Default value for max slots
        ip_rating: '',
        date_acquired: '',
        nickname: '',
        headstamp: '',
        notes: '',
        emitters: [{ type: '', driver_type: '', max_lumens: '', max_throw: '', stated_cct: '' }], // Include stated_cct
        assigned_battery_id: '' // Default value for assigned battery ID
    });

    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [availableBatteries, setAvailableBatteries] = useState([]); // Initialize with an empty array

    // Fetch available batteries from the server
    useEffect(() => {
        fetchBatteries()
            .then(data => setAvailableBatteries(data))
            .catch((error) => {
                console.error('Error fetching batteries:', error);
                setError('Failed to load batteries');
            });
    }, []);

    // Handle input changes for flashlight fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFlashlight({ ...flashlight, [name]: name === 'max_slots' ? parseInt(value) : value });
    };
   

    // Handle input changes for emitter fields
    const handleEmitterChange = (e, index) => {
        const { name, value } = e.target;
        const updatedEmitters = flashlight.emitters.map((emitter, i) =>
            i === index ? { ...emitter, [name]: value } : emitter
        );
        setFlashlight({ ...flashlight, emitters: updatedEmitters });
    };

    // Add a new emitter field dynamically
    const addEmitter = () => {
        setFlashlight({
            ...flashlight,
            emitters: [...flashlight.emitters, { type: '', driver_type: '', max_lumens: '', max_throw: '', stated_cct: '' }]
        });
    };

    // Remove an emitter field dynamically
    const removeEmitter = (index) => {
        const updatedEmitters = flashlight.emitters.filter((_, i) => i !== index);
        setFlashlight({ ...flashlight, emitters: updatedEmitters });
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        addFlashlight(flashlight)
            .then(() => {
                setMessage('Flashlight added successfully');
                setFlashlight({
                    make: '',
                    model: '',
                    battery_size: '',
                    battery_chemistry: '',
                    max_slots: 1,
                    ip_rating: '',
                    date_acquired: '',
                    nickname: '',
                    headstamp: '',
                    notes: '',
                    emitters: [{ type: '', driver_type: '', max_lumens: '', max_throw: '', stated_cct: '' }], // Reset emitters
                    assigned_battery_id: ''
                });

                if (onFlashlightAdded) {
                    onFlashlightAdded(); // Trigger the refresh in the parent component
                }
            })
            .catch((error) => {
                console.error('Error during submission:', error);
                setError('Error: ' + error.message);
            });
    };

    return (
        <div>
            <h2>Add Flashlight</h2>
            <form onSubmit={handleSubmit}>
                {/* Flashlight Fields */}
                <input name="make" placeholder="Make" value={flashlight.make} onChange={handleChange} />
                <input name="model" placeholder="Model" value={flashlight.model} onChange={handleChange} />
                <input name="battery_size" placeholder="Battery Size" value={flashlight.battery_size} onChange={handleChange} />
                <input name="battery_chemistry" placeholder="Battery Chemistry" value={flashlight.battery_chemistry} onChange={handleChange} />
                <input name="max_slots" type="number" min="1" value={flashlight.max_slots} onChange={handleChange} placeholder="Max Slots" />
                <input name="ip_rating" placeholder="IP Rating" value={flashlight.ip_rating} onChange={handleChange} />
                <input name="date_acquired" type="date" value={flashlight.date_acquired} onChange={handleChange} />
                <input name="nickname" placeholder="Nickname (Optional)" value={flashlight.nickname} onChange={handleChange} />
                <input name="headstamp" placeholder="Headstamp (Optional)" value={flashlight.headstamp} onChange={handleChange} />
                <input name="notes" placeholder="Notes (Optional)" value={flashlight.notes} onChange={handleChange} />

                {/* Battery Assignment */}
                <label>Assign Battery:</label>
                <select name="assigned_battery_id" value={flashlight.assigned_battery_id} onChange={handleChange}>
                    <option value="">-- Select Battery --</option>
                    {availableBatteries.length > 0 ? (
                        availableBatteries.map(battery => (
                            <option key={battery.id} value={battery.id}>
                                {battery.barcode}
                            </option>
                        ))
                    ) : (
                        <option disabled>No batteries available</option>
                    )}
                </select>

                {/* Emitters Fields */}
                <h3>Emitters</h3>
                {flashlight.emitters.map((emitter, index) => (
                    <div key={index} style={{ marginBottom: '10px' }}>
                        <input name="type" placeholder="Emitter Type" value={emitter.type} onChange={(e) => handleEmitterChange(e, index)} />
                        <input name="driver_type" placeholder="Driver Type" value={emitter.driver_type} onChange={(e) => handleEmitterChange(e, index)} />
                        <input name="max_lumens" placeholder="Max Lumens" value={emitter.max_lumens} onChange={(e) => handleEmitterChange(e, index)} />
                        <input name="max_throw" placeholder="Max Throw" value={emitter.max_throw} onChange={(e) => handleEmitterChange(e, index)} />
                        <input name="stated_cct" placeholder="Stated CCT" value={emitter.stated_cct} onChange={(e) => handleEmitterChange(e, index)} />
                        <button type="button" onClick={() => removeEmitter(index)}>Remove Emitter</button>
                    </div>
                ))}
                <button type="button" onClick={addEmitter}>Add Another Emitter</button>

                <button type="submit">Submit</button>
                {message && <p style={{ color: 'green' }}>{message}</p>}
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </form>
        </div>
    );
}

export default AddFlashlightForm;
