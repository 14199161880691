import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AUTH_TOKEN = 'Your-Secret-Token'; // Replace with your actual token

function AddBatteryForm({ batteryTypes = [], onBatteryAdded }) {  
  const [battery, setBattery] = useState({
    barcode: '',
    date_acquired: '',
    last_test_date: '',
    type_id: '',
    notes: '' 
  });

  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    try {
      const response = await axios.post('https://blackumbra.com/api/batteries', battery, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${AUTH_TOKEN}`
        }
      });

      if (response.status === 201) {
        setMessage('Battery added successfully');
        setBattery({
          barcode: '',
          date_acquired: '',
          last_test_date: '',
          type_id: '',
          notes: '' 
        });

        if (onBatteryAdded && typeof onBatteryAdded === 'function') {
          onBatteryAdded();  
        }
      } else {
        setError(response.data.message || 'Failed to add battery');
      }
    } catch (err) {
      console.error('Error submitting battery:', err);
      setError('Error: Failed to submit battery.');
    }
  };

  useEffect(() => {
    console.log('AddBatteryForm received new batteryTypes:', batteryTypes);
}, [batteryTypes]);


  // Sort battery types alphabetically by brand and model
  const sortedBatteryTypes = [...batteryTypes].sort((a, b) => {
    const typeA = `${a.brand} ${a.model}`.toLowerCase();
    const typeB = `${b.brand} ${b.model}`.toLowerCase();
    return typeA.localeCompare(typeB);
  });

  return (
    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <label htmlFor="barcode">Barcode</label>
      <input 
        id="barcode"
        name="barcode" 
        value={battery.barcode} 
        onChange={(e) => setBattery({...battery, barcode: e.target.value})} 
        placeholder="Barcode" 
      />

      <label htmlFor="date_acquired">Date Acquired</label>
      <input 
        id="date_acquired"
        name="date_acquired" 
        type="date" 
        value={battery.date_acquired} 
        onChange={(e) => setBattery({...battery, date_acquired: e.target.value})} 
      />

      <label htmlFor="last_test_date">Last Test Date</label>
      <input 
        id="last_test_date"
        name="last_test_date" 
        type="date" 
        value={battery.last_test_date} 
        onChange={(e) => setBattery({...battery, last_test_date: e.target.value})} 
      />

      <label htmlFor="type_id">Battery Type</label>
      <select 
        id="type_id"
        name="type_id" 
        value={battery.type_id} 
        onChange={(e) => setBattery({...battery, type_id: e.target.value})}
      >
        <option value="">Select Battery Type</option>
        {sortedBatteryTypes.length > 0 ? (
          sortedBatteryTypes.map(type => (
            <option key={type.id} value={type.id}>
              {type.brand} {type.model}
            </option>
          ))
        ) : (
          <option disabled>No battery types available</option>
        )}
      </select>

      <label htmlFor="notes">Notes</label>
      <input
        id="notes"
        name="notes" 
        value={battery.notes} 
        onChange={(e) => setBattery({...battery, notes: e.target.value})} 
        placeholder="Notes" 
      />

      <button type="submit">Add Battery</button>

      {error && <p style={{color: 'red'}}>{error}</p>}
      {message && <p style={{color: 'green'}}>{message}</p>}
    </form>
  );
}

export default AddBatteryForm;
