import axios from 'axios';

const API_URL = 'https://blackumbra.com/api';
const AUTH_TOKEN = 'Bearer Your-Secret-Token'; // Ensure the 'Bearer ' prefix is included

// Common headers object
const headers = {
    'Content-Type': 'application/json',
    'Authorization': AUTH_TOKEN
};

// Fetch all emitter test results for a specific flashlight
export const fetchEmitterTestsByFlashlightId = (flashlightId) => {
    return axios.get(`${API_URL}/flashlights/${flashlightId}/emitters`, { headers })
        .then(response => response.data)
        .catch(error => {
            console.error(`Error fetching emitter tests for flashlight ID ${flashlightId}:`, error);
            throw error;
        });
};

// Fetch all emitter test results
export const fetchEmitterTestResults = () => {
    return axios.get(`${API_URL}/emitter-test-results`, { headers })
        .then(response => response.data)
        .catch(error => {
            console.error('Error fetching all emitter test results:', error);
            throw error;
        });
};

// Update a specific emitter test result
export const updateEmitterTestResult = (testId, testData) => {
    return axios.put(`${API_URL}/emitter-test-results/${testId}`, testData, { headers })
        .then(response => response.data)
        .catch(error => {
            console.error(`Error updating emitter test result ID ${testId}:`, error);
            throw error;
        });
};

// Delete a specific emitter test result
export const deleteEmitterTestResult = (testId) => {
    return axios.delete(`${API_URL}/emitter-test-results/${testId}`, { headers })
        .then(response => response.status === 204)
        .catch(error => {
            console.error(`Error deleting emitter test result ID ${testId}:`, error);
            throw error;
        });
};
