import React, { useState, useEffect } from 'react';
import { fetchBatteries, fetchBatteryTypes, updateBatteryType } from '../services/batteryService';

function UpdateBatteryTypeForm({ onBatteryTypeUpdated }) {
    const [batteries, setBatteries] = useState([]);
    const [batteryTypes, setBatteryTypes] = useState([]);
    const [selectedBattery, setSelectedBattery] = useState(null);
    const [selectedType, setSelectedType] = useState(null);

    // Fetch batteries and battery types on component mount
    useEffect(() => {
        async function fetchData() {
            try {
                const fetchedBatteries = await fetchBatteries();
                const fetchedBatteryTypes = await fetchBatteryTypes();

                // Sort batteries by barcode in descending order
                const sortedBatteries = fetchedBatteries.sort((a, b) => {
                    const barcodeA = a.barcode || '';
                    const barcodeB = b.barcode || '';
                    return barcodeB.localeCompare(barcodeA); // Descending order
                });

                // Sort battery types alphabetically by brand and model
                const sortedBatteryTypes = fetchedBatteryTypes.sort((a, b) => {
                    const nameA = `${a.brand} ${a.model}`.toLowerCase();
                    const nameB = `${b.brand} ${b.model}`.toLowerCase();
                    return nameA.localeCompare(nameB); // Ascending alphabetical order
                });

                setBatteries(sortedBatteries);
                setBatteryTypes(sortedBatteryTypes);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedBattery || !selectedType) {
            alert('Please select both a battery and a type');
            return;
        }

        try {
            await updateBatteryType(selectedBattery, selectedType);
            alert('Battery type updated successfully!');
            if (onBatteryTypeUpdated && typeof onBatteryTypeUpdated === 'function') {
                onBatteryTypeUpdated();
            }
        } catch (error) {
            console.error('Failed to update battery type:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>Update Battery Type</h2>
            <label>
                Select Battery:
                <select value={selectedBattery} onChange={(e) => setSelectedBattery(e.target.value)}>
                    <option value="">-- Select Battery --</option>
                    {batteries.map(battery => (
                        <option key={battery.id} value={battery.id}>
                            {battery.barcode || `Battery ID: ${battery.id}`} (Current Type: {battery.brand} {battery.model})
                        </option>
                    ))}
                </select>
            </label>

            <label>
                Select New Type:
                <select value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
                    <option value="">-- Select New Type --</option>
                    {batteryTypes.map(type => (
                        <option key={type.id} value={type.id}>
                            {type.brand} {type.model} ({type.type})
                        </option>
                    ))}
                </select>
            </label>

            <button type="submit">Update Type</button>
        </form>
    );
}

export default UpdateBatteryTypeForm;
