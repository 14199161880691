import React, { useEffect, useState } from 'react';
import FlashlightBatteryAssignmentTable from '../components/FlashlightBatteryAssignmentTable';
import AssignBatteryForm from '../components/AssignBatteryForm';
import OtherBatteryAssignmentTable from '../components/OtherBatteryAssignmentTable'; // Import new component
import { fetchFlashlights } from '../services/flashlightService';
import { fetchAssignments, fetchOtherAssignments } from '../services/assignmentService'; // Fetch other assignments
import { fetchChargeSessions } from '../services/batteryChargeSessionService';

const AUTH_TOKEN = 'Your-Secret-Token';

function BatteryAssignmentPage() {
    const [assignments, setAssignments] = useState([]);
    const [flashlights, setFlashlights] = useState([]);
    const [otherAssignments, setOtherAssignments] = useState([]); // State for other assignments
    const [chargeSessions, setChargeSessions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [refreshTrigger, setRefreshTrigger] = useState(false);

    // Fetch flashlight assignments
    useEffect(() => {
        setLoading(true);
        fetchAssignments()
            .then(data => {
                setAssignments(data);
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching battery assignments:', err);
                setError('Failed to load battery assignments.');
                setLoading(false);
            });
    }, [refreshTrigger]);

    // Fetch "other" assignments
    useEffect(() => {
        fetchOtherAssignments() // Fetch assignments for non-flashlight objects
            .then(data => {
                setOtherAssignments(data);
            })
            .catch(err => {
                console.error('Error fetching other assignments:', err);
                setError('Failed to load other assignments.');
            });
    }, [refreshTrigger]);

    // Fetch flashlights data
    useEffect(() => {
        setLoading(true);
        fetchFlashlights()
            .then(data => {
                setFlashlights(data);
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching flashlights:', err);
                setError('Failed to load flashlights.');
                setLoading(false);
            });
    }, []);

    const handleAssignmentChanged = () => {
        setRefreshTrigger(prev => !prev); // Trigger refresh of both tables
    };

    if (loading) {
        return <p>Loading data...</p>;
    }

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    const handleOtherAssignmentRemoved = (assignmentId) => {
        fetch(`https://blackumbra.com/api/battery-assignments/other-assignments/${assignmentId}/remove`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${AUTH_TOKEN}`,
            },
        })
        .then(() => {
            handleAssignmentChanged(); // Trigger refresh after removal
        })
        .catch(error => console.error('Error removing other assignment:', error));
    };

    // Function to update other assignments
    const handleOtherAssignmentUpdated = (assignmentId, updatedData) => {
        fetch(`https://blackumbra.com/api/battery-assignments/other-assignments/${assignmentId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AUTH_TOKEN}`,
            },
            body: JSON.stringify(updatedData),
        })
        .then(() => {
            handleAssignmentChanged(); // Trigger refresh after update
        })
        .catch(error => console.error('Error updating other assignment:', error));
    };

    return (
        <div>
            <h2>Battery Assignments</h2>
            <AssignBatteryForm
                flashlights={flashlights}
                onAssignmentChanged={handleAssignmentChanged}
                refreshTrigger={refreshTrigger}
            />
            {/* Flashlight Assignments Table */}
            <FlashlightBatteryAssignmentTable
                assignments={assignments}
                flashlights={flashlights}
                onAssignmentRemoved={handleAssignmentChanged}
            />
            {/* Other Assignments Table */}
            <OtherBatteryAssignmentTable
                otherAssignments={otherAssignments}
                onAssignmentRemoved={handleOtherAssignmentRemoved}
                onAssignmentUpdated={handleOtherAssignmentUpdated}
            />
        </div>
    );
}

export default BatteryAssignmentPage;
