import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddEmitterTestResultsForm from '../components/AddEmitterTestResultsForm';  // Ensure the path is correct
import EmitterTestResultsTable from '../components/EmitterTestResultsTable';  // Ensure the path is correct

const AUTH_TOKEN = 'Your-Secret-Token'; // Replace with your actual token

function EmitterTestingPage() {
    const [emitterTests, setEmitterTests] = useState([]);
    const [flashlights, setFlashlights] = useState([]);
    const [emitters, setEmitters] = useState([]); // State to hold emitters data
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [refreshTrigger, setRefreshTrigger] = useState(0); // Track the refresh state

    // Fetch flashlights, emitters, and emitter test results whenever refreshTrigger changes
    useEffect(() => {
        fetchData();
    }, [refreshTrigger]); // Include refreshTrigger as a dependency

    const fetchData = async () => {
        setLoading(true);
        try {
            // Fetch flashlights and emitter test results first
            const [flashlightsResponse, emitterTestsResponse] = await Promise.all([
                axios.get('https://blackumbra.com/api/flashlights', {
                    headers: {
                        'Authorization': `Bearer ${AUTH_TOKEN}`
                    }
                }),
                axios.get('https://blackumbra.com/api/emitter-test-results', {
                    headers: {
                        'Authorization': `Bearer ${AUTH_TOKEN}`
                    }
                })
            ]);

            const flashlightsData = flashlightsResponse.data;
            const emitterTestsData = emitterTestsResponse.data;

            console.log('Fetched flashlights data:', flashlightsData); // Debug: Check flashlight data
            console.log('Fetched emitter tests data:', emitterTestsData); // Debug: Check emitter tests data

            setFlashlights(flashlightsData);
            setEmitterTests(emitterTestsData);

            // Now fetch emitters data for each flashlight
            const emitterPromises = flashlightsData.map(flashlight =>
                axios.get(`https://blackumbra.com/api/flashlights/${flashlight.id}/emitters`, {
                    headers: {
                        'Authorization': `Bearer ${AUTH_TOKEN}`
                    }
                })
            );

            // Wait for all emitter data to be fetched
            const emitterResults = await Promise.all(emitterPromises);

            // Create a map of flashlight ID to emitters
            const emittersMap = {};
            emitterResults.forEach((emitterResponse, index) => {
                const flashlightId = flashlightsData[index].id;
                const emittersData = emitterResponse.data;
                console.log('Fetched emitters for flashlight ID:', flashlightId, emittersData); // Debug: Check emitters data
                emittersMap[flashlightId] = emittersData;
            });

            console.log('Emitters map:', emittersMap); // Debug: Check emitters map
            setEmitters(emittersMap); // Set the emitters map in state
            setLoading(false);
        } catch (err) {
            console.error('Error fetching emitter test data:', err);
            setError('Failed to load emitter test data.');
            setLoading(false);
        }
    };

    const handleEmitterTestAdded = () => {
        // Increment the refresh trigger to refetch data
        setRefreshTrigger(prev => prev + 1);
    };

    if (loading) {
        return <p>Loading emitter test data...</p>;
    }

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    // Check emitterTests data structure
    console.log('Emitter Tests Data:', emitterTests); // Add this line to ensure we are receiving the correct data structure

    return (
        <div>
            <h2>Emitter Testing</h2>
            <AddEmitterTestResultsForm
                flashlights={flashlights}
                onEmitterTestAdded={handleEmitterTestAdded}
            />
            <EmitterTestResultsTable
                emitterTests={emitterTests}
                flashlights={flashlights}
                emitters={emitters} // Pass the emitters data as a prop
                refreshTrigger={refreshTrigger} // Pass the refresh trigger to force update if needed
            />
        </div>
    );
}

export default EmitterTestingPage;
